import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getUserList as getUserListApi,
  getUserMetrix as getUserMetrixApi,
  getUserProfile as getUserProfileApi,
  getUserDetails as getUserDetailsApi,
  addUserList as addUserListApi,
  updateUserList as updateUserListApi,
  deleteUserList as deleteUserListApi,
  getUserGrid as getUserGridApi,
  addUserGrid as addUserGridApi,
  updateUserGrid as updateUserGridApi,
  deleteUserGrid as deleteUserGridApi,
  getActiveUsers as getActiveUsersApi,
  getUserLeaveInfo as getUserLeaveInfoApi,
  getUsersBirthdays as getUsersBirthdaysApi,
  getUsersAnivarsaries as getUsersAnivarsariesApi,
  getUsersCelebrations as getUsersCelebrationsApi,
  uploadMultiPartFiles as uploadMultiPartFilesApi,
  getUserCelebrationMetrix as getUserCelebrationMetrixApi,
} from "../../services/http/api_service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getUserMetrix = createAsyncThunk(
  "users/getUserMetrix",
  async () => {
    try {
      const response = await getUserMetrixApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUserList = createAsyncThunk(
  "users/getUserList",
  async (params: any) => {
    try {
      const response: any = await getUserListApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getActiveUsers = createAsyncThunk(
  "users/getActiveUsers",
  async () => {
    try {
      const response = await getActiveUsersApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUserLeaveInfo = createAsyncThunk(
  "users/getUserLeaveInfo",
  async () => {
    try {
      const response: any = await getUserLeaveInfoApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "users/getUserProfile",
  async () => {
    try {
      const response: any = await getUserProfileApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUserCelebrationMetrix = createAsyncThunk(
  "users/getUserCelebrationMetrix",
  async () => {
    try {
      const response = await getUserCelebrationMetrixApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUsersBirthdays = createAsyncThunk(
  "users/getUsersBirthdays",
  async () => {
    try {
      const response: any = await getUsersBirthdaysApi();
      // console.log(response?.data, "what is the API returning");
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUsersAnivarsaries = createAsyncThunk(
  "users/getUsersAnivarsaries",
  async () => {
    try {
      const response: any = await getUsersAnivarsariesApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUsersCelebrations = createAsyncThunk(
  "users/getUsersCelebrations",
  async () => {
    try {
      const response: any = await getUsersCelebrationsApi();

      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "users/getUserDetails",
  async (id: any) => {
    try {
      const response: any = await getUserDetailsApi(id);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const addUserList = createAsyncThunk(
  "users/getUserDetails",
  async (event: any) => {
    try {
      const added: any = await addUserListApi(event);

      const response: any = await added?.data;

      if (response.code === 200) {
        await toast.success(response?.message, { autoClose: 2000 });
      } else {
        await toast.error(added?.meassage, { autoClose: 2000 });
      }

      return response;
    } catch (error: any) {
      toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
      return error;
    }
  }
);

export const uploadMultiPartFiles = createAsyncThunk("", async (event: any) => {
  try {
    const response = await uploadMultiPartFilesApi(event);
    const data = await response;
    toast.success("User Added Successfully", { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error("User Added Failed", { autoClose: 2000 });
    return error;
  }
});

export const updateUserList = createAsyncThunk(
  "users/getUserDetails",
  async (event: any) => {
    try {
      // console.log("event", event);
      const success: any = await updateUserListApi(event);

      const updated: any = success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }

      // console.log("event id", event.id);

      const response: any = await getUserDetailsApi(event?.id);

      return response?.data;
    } catch (error: any) {
      toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });

      return error;
    }
  }
);

export const deleteUserList = createAsyncThunk(
  "users/deleteUserList",
  async (event: any) => {
    try {
      const response: any = await deleteUserListApi(event);
      toast.success("User deleted Successfully", { autoClose: 2000 });
      return response?.data;
    } catch (error) {
      toast.error("User deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const getUserGrid = createAsyncThunk("users/getUserGrid", async () => {
  try {
    const response: any = await getUserGridApi();
    return response?.data;
  } catch (error) {
    return error;
  }
});

export const addUserGrid = createAsyncThunk(
  "users/addUserGrid",
  async (event: any) => {
    try {
      const response = addUserGridApi(event);
      const data = await response;
      toast.success("User Added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("User Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const updateUserGrid = createAsyncThunk(
  "users/updateUserGrid",
  async (event: any) => {
    try {
      const response = updateUserGridApi(event);
      const data = await response;
      toast.success("User updated Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("User updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteUserGrid = createAsyncThunk(
  "users/deleteUserGrid",
  async (event: any) => {
    try {
      const response: any = deleteUserGridApi(event);
      toast.success("User deleted Successfully", { autoClose: 2000 });
      return response?.data;
    } catch (error) {
      toast.error("User deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
