import React, { useCallback, useEffect, useMemo, useState } from "react";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import BreadCrumb from "Common/BreadCrumb";
import TableContainer from "Common/TableContainer";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

// Icons
import {
  Search,
  Plus,
  Pencil,
  Info,
  FileBarChart2,
  CalendarDays,
  Stethoscope,
  Anchor,
  Trash2,
  Check,
  X,
  Eye,
  InfoIcon,
  CheckSquare,
} from "lucide-react";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import DeleteModal from "Common/DeleteModal";
import ApprovalModal from "Common/ApprovalModal";
import Modal from "Common/Components/Modal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getUserAppraisals as onGetUserAppraisals,
  getSupervisorAppraisals as onGetSupervisorAppraisals,
  getAppraisalSupervisorMetrix as onGetSupervisorAppraisalMetrix,
} from "slices/thunk";

import { toast, ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";

const AppraisalRequest = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (state) => ({
      dataList: state.supervisorAppraisals,
      leaveMetrix: state.appraisalSupervisorMetrix,
    })
  );

  const { dataList, leaveMetrix } = useSelector(selectDataList);

  const [data, setData] = useState<any>([]);
  const [metrix, setLeaveMetrix] = useState<any>({});
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const perPageData = 12;

  // largeModal
  const [largeModal, setLargeModal] = useState<boolean>(false);
  const largeToggle = () => setLargeModal(!largeModal);

  // Get Data
  useEffect(() => {
    dispatch(onGetSupervisorAppraisalMetrix());
    dispatch(
      onGetSupervisorAppraisals({ page: currentPage, per_page: perPageData })
    );
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
    setLeaveMetrix(leaveMetrix?.data);
  }, [dataList, leaveMetrix]);
  
  const [appraisalType, setCurrentType] = useState<any>('appraisal');
  const [appraisalStatus, setStatusType] = useState<any>('');

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    dispatch(onGetSupervisorAppraisals({ page: currentPage, per_page: perPageData, search: search, type: appraisalType, status: appraisalStatus}));
    const keysToSearch = ['supervisor_name', 'employee_name', 'employee_role', 'ranking', 'status'];
    filterDataBySearch(data, search, keysToSearch, setData);
  };

  const filterTypeData = (e: any) => {
    const type = e.target.value;
    setCurrentType(type);
    dispatch(onGetSupervisorAppraisals({ page: currentPage, per_page: perPageData, type: type, status: appraisalStatus}));
    const keysToSearch = ['type', 'status'];
    filterDataBySearch(data, type, keysToSearch, setData);
  };

  const filterStatusData = (status: string) => {

    console.log("Status:", status);
    setStatusType(status);
    dispatch(onGetSupervisorAppraisals({ page: currentPage, per_page: perPageData, type: appraisalType, status: status}));
    const keysToSearch = ['type', 'status'];
    filterDataBySearch(data, status, keysToSearch, setData);
  };

  // Table Head Action On Right
  useEffect(() => {
    const tableHead = document.querySelectorAll("th");
    const lastTheadElement = tableHead[tableHead.length - 1];
    lastTheadElement?.classList.add("ltr:text-right", "rtl:text-left");

    return () => {
      lastTheadElement?.classList.remove("ltr:text-right", "rtl:text-left");
    };
  });

  const Status = ({ item }: any) => {
    switch (item) {
        case "approved":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">Concluded</span>);
        case "pending":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">Pending</span>);
        case "opened":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-custom-100 border-transparent text-custom-500 dark:bg-custom-500/20 dark:border-transparent">Opened</span>);
        case "appraised":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-purple-100 border-transparent text-purple-500 dark:bg-purple-500/20 dark:border-transparent">Appraised</span>);
        case "reviewed":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">Reviewed</span>);
        case "closed":
              return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-orange-100 border-transparent text-orange-500 dark:bg-orange-500/20 dark:border-transparent">Closed, Await Approval</span>);
        case "updated":
          return ( <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">Appraised</span>);
        case "Inprogress":
          return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">Inprogress</span>);
        default:
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:border-transparent">{item}</span>);
    }
  };

  const Type = ({ item }: any) => {
    switch (item) {
      case "half_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Half Year
          </span>
        );
      case "full_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Full Year
          </span>
        );
      case "confirmation":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Confirmations
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            {item}
          </span>
        );
    }
  };

  const columns = useMemo(
    () => [
      // {
      //   header: (
      //     <div className="flex items-center h-full">
      //       <input
      //         id="CheckboxAll"
      //         className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
      //         type="checkbox"
      //       />
      //     </div>
      //   ),
      //   enableSorting: false,
      //   id: "checkAll",
      //   cell: (cell: any) => {
      //     return (
      //       <div className="flex items-center h-full">
      //         <input
      //           id="Checkbox1"
      //           className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
      //           type="checkbox"
      //         />
      //       </div>
      //     );
      //   },
      // },
      {
        header: "Appraisal ID",
        accessorKey: "ref_no",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to={`/appaisals/${cell.row.original.id}`}
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            <Type item={cell.getValue()} />
          </Link>
        ),
      },
      {
        header: "Staff",
        accessorKey: "employee_name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.employee_image ? (
                <img
                  src={cell.row.original.employee_image}
                  alt=""
                  className="h-10 rounded-full"
                />
              ) : (
                ""
              )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.employee_role}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Type",
        accessorKey: "appraisal_category.type",
        enableColumnFilter: false,
        cell: (cell: any) => <Type item={cell.getValue()} />,
      },
      {
        header: "Period",
        accessorKey: "appraisal_category.year",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.row.original.appraisal_category?.month}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.getValue()}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Supervisor",
        accessorKey: "supervisor_name",
        enableColumnFilter: false,
      },
      {
        header: "Scores",
        accessorKey: "total_percentage",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <b>{cell.getValue()}%</b>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      }, 
      {
        header: "Start Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to={`/appaisals/${cell.row.original.id}`}
            >
              <Eye className="inline-block size-3" />{" "}
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <BreadCrumb title="Appraisal Request" pageTitle="Appraisal" />
      {/* <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} />
            <ApprovalModal show={deleteModal} status={approvalStatus} onHide={deleteToggle} onDelete={handleApproval} /> */}
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-12">
        <div className="xl:col-span-4">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-700">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-750 rounded-md text-15 shrink-0">
                <CalendarDays />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={metrix?.opened}
                    className="counter-value text-white"
                  />
                </h5>
                <p className="text-white">Opened</p>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:col-span-4">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-800">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-850 rounded-md text-15 shrink-0">
                <FileBarChart2 />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16 text-white">
                  <CountUp end={metrix?.appraised} className="counter-value" />
                </h5>
                <p className="text-white">Appraised</p>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:col-span-4">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-900">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-950 rounded-md text-15  shrink-0">
                <CheckSquare />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16 text-white">
                  <CountUp end={metrix?.reviewed} className="counter-value" />
                </h5>
                <p className="text-white">Reviewed</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="grid grid-cols-1 gap-4 mb-5 lg:grid-cols-2 xl:grid-cols-12">
            <div className="xl:col-span-3">
              <div className="relative">
                <input
                  type="text"
                  className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Search for ..."
                  autoComplete="off"
                  onChange={(e) => filterSearchData(e)}
                />
                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
              </div>
            </div>
            <div className="xl:col-span-9 gap-4">
              <div className="ltr:lg:text-right rtl:lg:text-left">
                <div className="shrink-0">
                  <button type="button" onClick={(e) => { e.preventDefault(); filterStatusData('')}} className="bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500">All</button>
                  <button type="button"  onClick={(e) => { e.preventDefault(); filterStatusData('opened')}} className="bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500">Opened</button>
                  <button type="button" onClick={(e) => {e.preventDefault(); filterStatusData('appraised')}} className="bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500">Appraised</button>
                  <button type="button" onClick={() =>  filterStatusData('reviewed')} className="bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500">Reviewed</button>
                  <button type="button" onClick={() => filterStatusData('closed')} className="bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500">Closed</button>            
                  <button type="button" onClick={() => filterStatusData('approved')} className="bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500">Concluded</button>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            {data && data.length > 0 ? (
              <TableContainer
                isPagination={true}
                columns={columns || []}
                data={data || []}
                customPageSize={10}
                divclassName="overflow-x-auto"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:bg-zink-600 dark:text-zink-200"
                thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            ) : (
              <div className="noresult">
                <div className="py-6 text-center">
                  <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                  <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={largeModal}
        onHide={largeToggle}
        id="largeModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border border-custom-500 dark:border-custom-800"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
        >
          <Modal.Title className="text-16">
            Appraisal & Confirmation Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <h5 className="mb-3 text-16">
            <u>Summary</u>
          </h5>

          <h3 className="mb-3 text-16">
            <u>The purpose of the confirmation review:</u>
          </h3>

          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              Review the progress and performance demonstrated to date.
            </span>
          </li>
          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              Compare actual performance with agreed goals for the probation
              period.
            </span>
          </li>
          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              To serve as a basis for modifying or changing work patterns and
              behaviour towards more effective working habits.
            </span>
          </li>

          <h3 className="mb-2 text-16 mt-3">
            <u>Instructions and Guidelines:</u>
          </h3>

          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              {" "}
              The appraisee initiates the confirmation appraisal process by
              completing this appraisal form.
            </span>
          </li>
          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              Discussion between the appraisee and the supervisor is mandatory
              prior to sign off on the form.
            </span>
          </li>
          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              All forms must be filled and completed within five (5) days of
              commencement of the appraisals.
            </span>
          </li>

          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              Supervisors are expected to review employee performance based on
              the goals agreed.
            </span>
          </li>

          <h6 className="mb-4 mt-4 text-15">Overall Ranking</h6>
          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">
                    GRADE
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">
                    RANKING
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">
                    WEIGHTED SCORE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      5
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Outstanding
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved at least 80% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      4
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Good
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved at least 70% - 79% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      3
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Satisfactory
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved at least 60% - 69% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      2
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Weak
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved at least 500% - 59% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      1
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Very Weak
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved below 49% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      0
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    NA
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Not Applicable to the staff
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between p-4 mt-auto border-t border-slate-200 dark:border-zink-500">
          <h5 className="text-16">Best of Luck</h5>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AppraisalRequest;
