import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import WelcomeWidget from "./WelcomeWidget";
import Widgets from "./Widgets";
import BirthdayWFHDashboard from "./BirthdayWFHDashboard";
import TaskDashboard from "./TaskDashboard";
import { useNavigate } from "react-router-dom";
import {
  getTaskBoard as onGetTaskBoard,
  getTaskMetrix as onGetTaskMetrix,
  getUserProfile as onGetUserProfile,
  getWfhSupervisorDashboard as onGetSupervisorWfh,
  getUsersBirthdays as onGetUsersBirthdays,
  getDepartmentalTask as onGetDepartmentalTask,
  getUsersAnivarsaries as onGetUsersAnivarsaries,
  getUsersCelebrations as onGetUsersCelebrations,
  getAppraisalSupervisorDashboard as onGetSupervisorAppraisals,
  getUserLeaveApplications as onGetUserLeaveApplications,
  getUserCelebrationMetrix as onGetUserCelebrationMetrix,
  getDashboardAdminRequests as onGetDashboardAdminRequests,
  getLeaveSupervisorDashboard as onGetSupervisorLeaveApplications,
  checkOpenedAppraisalCategories as onCheckOpenedAppraisalCategories,
} from "slices/thunk";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

const Dashboard = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.Users,
    (hrState: any) => hrState.HRManagment,
    (user, hrState) => ({
      taskBoard: hrState.taskBoard,
      profileList: user.userProfile,
      taskMetrix: hrState.taskMetrix,
      userBirthdays: user.userBirthdays,
      userLeaveList: hrState.userLeaveApplications,
      userAnivarsaries: user.userAnivarsaries,
      userCelebrations: user.userCelebrations,
      userCelebrationMetrix: user.userCelebrationMetrix,
      checkAppraisal: hrState.checkAppraisalCategories,
      dashboardAdminRequests: hrState.dashboardAdminRequests,
    })
  );

  // const { profileList, checkAppraisal } = useSelector(selectDataList);
  // const [user, setProfile] = useState<any>([]);
  // Get Data
  useEffect(() => {
    dispatch(onGetSupervisorWfh());
    dispatch(onGetUsersBirthdays());
    dispatch(onGetUsersCelebrations());
    dispatch(onGetUsersAnivarsaries());
    dispatch(onGetUserCelebrationMetrix());
    dispatch(onGetDashboardAdminRequests());
    dispatch(onCheckOpenedAppraisalCategories());
    dispatch(
      onGetTaskBoard({ page: 1, per_page: 10 })
    );
    dispatch(
      onGetTaskMetrix({ page: 1, per_page: 10 })
    );
    dispatch(
      onGetUserLeaveApplications({ page: 1, per_page: 10 })
    );
    dispatch(
      onGetSupervisorLeaveApplications()
    );
    dispatch(
      onGetSupervisorAppraisals()
    );
  }, [dispatch]);

  return (
    <React.Fragment>
      <BreadCrumb title="Staff Dashboard" pageTitle="Dashboard" />
      <div className="grid grid-cols-12 gap-x-6">
        <WelcomeWidget />
        <BirthdayWFHDashboard />
        {/* <OrderStatistics /> */}
        <Widgets />
        <TaskDashboard />
        {/* <UpcomingScheduled /> */}
        {/* <TrafficResources />
                <ProductsOrders />
                <CustomerService />
                <SalesMonth />
                <TopSellingProducts />
                <Audience /> */}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
