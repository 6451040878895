import React, { useEffect, useMemo, useState } from "react";

// Images
// import birthdayBaloon from "assets/images/birthdayBaloon.png"
// import anniversary from "assets/images/anniversary.svg";
import birthdayBaloon from "assets/images/birthdayBaloon.png";
import workBaloon from "assets/images/workBaloon.svg";

// Icons
import { MoveUpRight, MoveDownLeft, MoreVertical, Search } from "lucide-react";

// SimpleBar
import SimpleBar from "simplebar-react";
import { Dropdown } from "Common/Components/Dropdown";
import { Link } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import TableContainer from "Common/TableContainer";

const BirthdayWFHDashboard = () => {
  const selectDataList = createSelector(
    (state: any) => state.Users,
    (hrState: any) => hrState.HRManagment,
    (user, hrState) => ({
      userBirthdays: user.userBirthdays,
      userAnivarsaries: user.userAnivarsaries,
      userCelebrations: user.userCelebrations,
      userLeaveList: hrState.userLeaveApplications,
      userCelebrationMetrix: user.userCelebrationMetrix,
    })
  );

  const {
    userLeaveList,
    userBirthdays,
    userAnivarsaries,
    userCelebrations,
    userCelebrationMetrix,
  } = useSelector(selectDataList);
  const [birthdays, setBirthdays] = useState<any>([]);
  const [anniversaries, setAnniversaries] = useState<any>([]);
  const [celebrations, setCelebrations] = useState<any>([]);
  const [leaves, setUserLeaves] = useState<any>([]);
  const [celebrationMetrix, setCelebrationMetrix] = useState<any>([]);
  // console.log(birthdays, "what is happening to birthday list");
  useEffect(() => {
    setUserLeaves(userLeaveList?.data);
    setBirthdays(userBirthdays?.data);
    setAnniversaries(userAnivarsaries?.data);
    setCelebrations(userCelebrations?.data);
    setCelebrationMetrix(userCelebrationMetrix?.data);
  }, [
    userLeaveList,
    userBirthdays,
    userAnivarsaries,
    userCelebrations,
    userCelebrationMetrix,
  ]);

  const LeaveStatus = ({ item }: any) => {
    switch (item) {
      case "approved":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            Approved
          </span>
        );
      case "pending":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Pending
          </span>
        );
      case "declined":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent">
            Declined
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            {item}
          </span>
        );
    }
  };

  const Type = ({ item }: any) => {
    switch (item) {
      case "half_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Half Year
          </span>
        );
      case "full_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Full Year
          </span>
        );
      case "confirmation":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Confirmations
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            {item}
          </span>
        );
    }
  };

  const LeaveColumns = useMemo(
    () => [
      // {
      //   header: "#",
      //   accessorKey: "ref_no",
      //   enableColumnFilter: false,
      //   cell: (cell: any) => (
      //     <Link
      //       to="!#"
      //       className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
      //     >
      //       {" "}
      //       <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
      //         {cell.getValue()}
      //       </span>
      //     </Link>
      //   ),
      // },

      // {
      //   header: "Leave Type",
      //   accessorKey: "type_name",
      //   enableColumnFilter: false,
      // },

      {
        header: "Start Date",
        accessorKey: "start_date",
        enableColumnFilter: false,
      },
      {
        header: "End",
        accessorKey: "end_date",
        enableColumnFilter: false,
      },
      {
        header: "Days",
        accessorKey: "leave_days",
        enableColumnFilter: false,
      },
      {
        header: "Days Out",
        accessorKey: "total_leave_days",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => <LeaveStatus item={cell.getValue()} />,
      },
    ],
    []
  );

  const BStatus = ({ item }: any) => {
    switch (item) {
      case "Birthday":
        return (
          <span className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            {item}
          </span>
        );
      case "Anniversary":
        return (
          <span className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            {item}
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 inline-block text-[11px] font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            {item}
          </span>
        );
    }
  };

  return (
    <React.Fragment>
      <div className="col-span-12 md:col-span-4 md:row-span-4">
        <div className="grid grid-cols-12 gap-x-5">
          {birthdays?.length > 0 &&
            birthdays.map((birthday: any, index: any) => (
              <div
                key={index}
                className="relative col-span-12 card bg-gradient-to-r to-custom-100 dark:to-custom-500/20 from-transparent md:mt-4"
              >
                <div className="bg-[url('../images/hr-dashboard.png')] absolute inset-0 bg-cover opacity-30"></div>
                <div className="relative card-body">
                  <div className="flex gap-3 mb-4">
                    <div className="size-10 bg-purple-100 rounded-full dark:bg-purple-500/20 shrink-0">
                      <img
                        src={birthday?.image}
                        alt=""
                        className="h-10 rounded-full"
                      />
                    </div>
                    <div className="grow">
                      <h6 className="mb-1">
                        {birthday?.firstname} {birthday?.lastname}
                      </h6>
                      <p className="text-slate-500 dark:text-zink-200">
                        {" "}
                        Today is {birthday?.gender === "Male"
                          ? "his"
                          : "her"}{" "}
                        birthday
                      </p>
                    </div>
                  </div>
                  {/* <button
                    type="button"
                    className="px-2 py-1.5 text-xs text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    Wish {birthday?.gender === "Male" ? "Him" : "Her"}
                  </button> */}
                </div>
                <img
                  src={birthdayBaloon}
                  alt=""
                  className="absolute bottom-0 right-0"
                />
              </div>
            ))}

          {anniversaries?.length > 0 &&
            anniversaries?.map((anniversary: any, index: any) => (
              <div
                key={index}
                className="relative col-span-12 card bg-gradient-to-r to-custom-100 dark:to-custom-500/20 from-transparent md:mt-4"
              >
                <div className="bg-[url('../images/hr-dashboard.png')] absolute inset-0 bg-cover opacity-30"></div>
                <div className="relative card-body">
                  <div className="flex gap-3 mb-4">
                    <div className="size-10 bg-purple-100 rounded-full dark:bg-purple-500/20 shrink-0">
                      <img
                        src={anniversary?.image}
                        alt=""
                        className="h-10 rounded-full"
                      />
                    </div>
                    <div className="grow">
                      <h6 className="mb-1">
                        {anniversary?.firstname} {anniversary?.lastname}
                      </h6>
                      <p className="text-slate-500 dark:text-zink-200">
                        {" "}
                        Today is{" "}
                        {anniversary?.gender === "Male" ? "his" : "her"} work
                        anniversary
                      </p>
                    </div>
                  </div>
                  {/* <button
                    type="button"
                    className="px-2 py-1.5 text-xs text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    Wish {anniversary?.gender === "Male" ? "Him" : "Her"}
                  </button> */}
                </div>
                <img
                  src={workBaloon}
                  width="25%"
                  alt=""
                  className="absolute bottom-0 right-0"
                />
              </div>
            ))}
          {birthdays?.length === 0 && anniversaries?.length === 0 && (
            <div className="relative col-span-12 card bg-gradient-to-r to-custom-100 dark:to-custom-500/20 from-transparent md:mt-4 ">
              <div className="bg-[url('../images/hr-dashboard.png')] absolute inset-0 bg-cover opacity-30"></div>
              <div className="relative card-body">
                <div className="text-center gap-3 mb-4">
                  <div className="grow items-center justify-center">
                    <h6 className="mb-1"> No Celebrant Today</h6>
                    {/* <p className="text-slate-500 dark:text-zink-200">
            {" "}
            No Celebrant Yet
          </p> */}
                  </div>
                </div>
              </div>
              <img
                src={birthdayBaloon}
                alt=""
                className="absolute bottom-0 right-0"
              />
            </div>
          )}

          <div className="col-span-12 card">
            <div className="!pb-0 card-body">
              <div className="flex items-center gap-2 mb-3">
                <h6 className="text-15 grow">Upcoming Celebrations</h6>
              </div>
            </div>

            <div className="pb-5">
              <SimpleBar className="flex flex-col h-[300px] gap-4 px-5">
                <div className="flex flex-col gap-3">
                  {(celebrations || []).map((item: any, key: number) => (
                    <div
                      className="flex flex-wrap items-center gap-3"
                      key={key}
                    >
                      <div className="size-10 bg-purple-100 rounded-full dark:bg-purple-500/20 shrink-0">
                        <img
                          src={item?.image}
                          alt=""
                          className="h-10 rounded-full"
                        />
                      </div>
                      <div className="grow">
                        <b className="mb-0">
                          {item?.firstname} {item?.lastname}
                        </b>
                        <h6>{item?.date}</h6>
                      </div>
                      <div className="w-15 ltr:text-right rtl:text-left shrink-0">
                        <BStatus item={item?.type} />
                      </div>
                    </div>
                  ))}
                </div>
              </SimpleBar>
            </div>
          </div>
          <div className="col-span-12 card">
            <div className="!pb-0 card-body">
              <div className="flex items-center gap-2 mb-3">
                <h6 className="text-15 grow">Leave History</h6>
                <button>
                  <Link
                    to={`/leave-applications`}
                    className="px-3 py-2 text-xs font-medium inline-block rounded border transition-all duration-200 ease-linear bg-blue-950 border-transparent text-white hover:bg-blue-600 dark:bg-blue-400/20 dark:hover:bg-blue-400/30 dark:border-transparent ltr:ml-1 rtl:mr-1"
                  >
                    Apply
                  </Link>
                </button>
              </div>
            </div>

            <div className="pb-5">
              <SimpleBar className="flex flex-col h-[198px] gap-4 px-5">
                <div className="text-center gap-3 mb-4">
                  {leaves && leaves.length > 0 ? (
                    <TableContainer
                      isPagination={false}
                      columns={LeaveColumns || []}
                      data={leaves || []}
                      customPageSize={10}
                      divclassName="overflow-x-auto"
                      tableclassName="w-full whitespace-nowrap"
                      theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:bg-zink-600 dark:text-zink-200"
                      thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                      tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                      PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                    />
                  ) : (
                    <div className="noresult">
                      <div className="py-6 text-center">
                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                        <h5 className="mt-2 mb-1">Sorry! No Data Yet</h5>
                        {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                            We've searched more than 5+ Departments We did not find any
                            Departments for you search.
                          </p> */}
                      </div>
                    </div>
                  )}
                </div>
              </SimpleBar>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BirthdayWFHDashboard;
