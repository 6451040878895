import React, { useEffect, useState } from "react";
import { Package, PackageX, Truck, Wallet2, SquareUser, CalendarClock, Trophy } from "lucide-react";
import CountUp from "react-countup";
//i18n
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

const Widgets = () => {

  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.Users,
    (hrState: any) => hrState.HRManagment,
    (state, hrState) => ({
      profileList: state.userProfile,
      taskMetrix: hrState.taskMetrix,
      dataList: hrState.dashboardAdminRequests,
    })
  );
  const { dataList, taskMetrix, profileList } = useSelector(selectDataList);
  const [user, setProfile] = useState<any>({});
  const [request, setData] = useState<any>({});
  const [metrix, setMetrix] = useState<any>({});

  useEffect(() => {
    setProfile(profileList?.data);
    setData(dataList?.data);
    setMetrix(taskMetrix?.data);
  }, [profileList, taskMetrix, dataList]);
  
  return (
    <React.Fragment>

      {user?.isAdmin == true ? (
        <><div className="col-span-12 card overflow-hidden sm:col-span-6 md:col-span-4 lg:col-span-2 bg-[#1087BD] dark:bg-[#1087BD]/20 rounded-lg shadow-md">
        <div className="text-center card-body">
          <div className="flex items-center justify-center mx-auto rounded-full size-14 bg-[#135979] text-white dark:bg-blue-600/20">
            <SquareUser/>
          </div>
          <h5 className="mt-4 mb-2">
            <CountUp end={request?.wfh} className="counter-value text-white" />
          </h5>
          <p className="text-white dark:text-zinc-200">WFH</p>
        </div>
        </div>
      
        <div
          className="col-span-12 card overflow-hidden sm:col-span-6 md:col-span-4 lg:col-span-2 bg-[#405191] dark:bg-[#405191]/20 rounded-lg shadow-md"
        >
          <div className="text-center card-body">
            <div className="flex items-center justify-center mx-auto text-white bg-[#27366E] rounded-full size-14 dark:bg-[#27366E]/20">
              <CalendarClock />
            </div>
            <h5 className="mt-4 mb-2">
              <CountUp end={request?.leave} className="counter-value text-white" />
            </h5>
            <p className="text-white dark:text-zinc-200">Pending Leaves</p>
          </div>
        </div>
      
        <div className="col-span-12 card overflow-hidden sm:col-span-6 md:col-span-4 lg:col-span-2 bg-[#E0763C] dark:bg-[#E0763C]/10 rounded-lg shadow-md">
          <div className="text-center card-body">
            <div className="flex items-center justify-center mx-auto text-white bg-[#84421F] rounded-full size-14 dark:bg-[#84421F]/20">
              <Trophy />
            </div>
            <h5 className="mt-4 mb-2">
              <CountUp end={request?.appraisal} className="counter-value text-white" />
            </h5>
            <p className="text-white dark:text-zinc-200">Pending Appraisals</p>
          </div>
        </div>
      
        <div className="col-span-12 card overflow-hidden sm:col-span-6 md:col-span-4 lg:col-span-2 bg-[#2B8B8A] dark:bg-[#2B8B8A]/20 rounded-lg shadow-md">
          <div className="text-center card-body">
            <div className="flex items-center justify-center mx-auto text-white bg-[#185D5D] rounded-full size-14 dark:bg-green-800/20">
              <Wallet2 />
            </div>
            <h5 className="mt-4 mb-2">
              <CountUp end={request?.task} className="counter-value text-white" />
            </h5>
            <p className="text-white dark:text-zinc-200">Tasks</p>
          </div>
        </div></>
      ) 
      
      :

      <>  <div className="col-span-12 card overflow-hidden sm:col-span-6 md:col-span-4 lg:col-span-2 bg-[#1087BD] dark:bg-[#1087BD]/20 rounded-lg shadow-md">
      <div className="text-center card-body">
        <div className="flex items-center justify-center mx-auto rounded-full size-14 bg-[#135979] text-white dark:bg-blue-600/20">
          <Wallet2 />
        </div>
        <h5 className="mt-4 mb-2">
          <CountUp end={metrix?.total?.count} className="counter-value text-white" />
        </h5>
        <p className="text-white dark:text-zinc-200">Total Task</p>
      </div>
      </div>
    
      <div
        className="col-span-12 card overflow-hidden sm:col-span-6 md:col-span-4 lg:col-span-2 bg-[#405191] dark:bg-[#405191]/20 rounded-lg shadow-md"
      >
        <div className="text-center card-body">
          <div className="flex items-center justify-center mx-auto text-white bg-[#27366E] rounded-full size-14 dark:bg-[#27366E]/20">
            <Truck />
          </div>
          <h5 className="mt-4 mb-2">
            <CountUp end={metrix?.inprgress?.count} className="counter-value text-white" />
          </h5>
          <p className="text-white dark:text-zinc-200">In Progress</p>
        </div>
      </div>
    
      <div className="col-span-12 card overflow-hidden sm:col-span-6 md:col-span-4 lg:col-span-2 bg-[#E0763C] dark:bg-[#E0763C]/10 rounded-lg shadow-md">
        <div className="text-center card-body">
          <div className="flex items-center justify-center mx-auto text-white bg-[#84421F] rounded-full size-14 dark:bg-[#84421F]/20">
            <PackageX />
          </div>
          <h5 className="mt-4 mb-2">
            <CountUp end={metrix?.done?.count} className="counter-value text-white" />
          </h5>
          <p className="text-white dark:text-zinc-200">Done</p>
        </div>
      </div>
    
      <div className="col-span-12 card overflow-hidden sm:col-span-6 md:col-span-4 lg:col-span-2 bg-[#2B8B8A] dark:bg-[#2B8B8A]/20 rounded-lg shadow-md">
        <div className="text-center card-body">
          <div className="flex items-center justify-center mx-auto text-white bg-[#185D5D] rounded-full size-14 dark:bg-green-800/20">
            <PackageX />
          </div>
          <h5 className="mt-4 mb-2">
            <CountUp end={metrix?.completed?.count} className="counter-value text-white" />
          </h5>
          <p className="text-white dark:text-zinc-200">Completed</p>
        </div>
      </div></>

      }


      {/* <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-2 gap-4">
        <div className="text-center card-body bg-blue-600 dark:bg-blue-600/20">
          <div className="flex items-center justify-center mx-auto rounded-full size-14 bg-custom-100 text-blue-600 dark:bg-blue-600/20">
            <Wallet2 />
          </div>
          <h5 className="mt-4 mb-2">
            {/* <CountUp end={236} decimals={2} className="counter-value" /> 
            <CountUp end={0} className="counter-value text-white" />
          </h5>
          <p className="text-white dark:text-zink-200">Total Task</p>
        </div>
      </div>
      <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-2 gap-4">
        <div className="text-center card-body bg-orange-800 dark:bg-orange-800/20">
          <div className="flex items-center justify-center mx-auto text-orange-800 bg-orange-100 rounded-full size-14 dark:bg-orange-800/20">
            <Truck />
          </div>
          <h5 className="mt-4 mb-2">
            <CountUp end={0} className="counter-value text-white" />
          </h5>
          <p className="text-white dark:text-zink-200">In Progress</p>
        </div>
      </div>
      <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-2">
        <div className="text-center card-body bg-green-800 dark:bg-green-800/20">
          <div className="flex items-center justify-center mx-auto text-green-800 bg-green-100 rounded-full size-14 dark:bg-green-800/20">
            <PackageX />
          </div>
          <h5 className="mt-4 mb-2">
            <CountUp end={0} className="counter-value text-white" />
          </h5>
          <p className="text-white dark:text-zink-200">Completed</p>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default Widgets;
