import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

// Icons
import { Search, Plus, Trash2, Pencil, Download, SlidersHorizontal } from "lucide-react";
import TableContainer from "Common/TableContainer";

import { Link } from "react-router-dom";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getAppraisalKpis as onGetAppraisalKpis,
  getAppraisalKpiDetails as onGetAppraisalKpiDetails,
  getActiveAppraisalKpis as onGetActiveAppraisalKpis,
  addAppraisalKpi as onAddAppraisalKpi,
  updateAppraisalKpi as onUpdateAppraisalKpi,
  deleteAppraisalKpi as onDeleteAppraisalKpi,
  getActiveRoles as onGetActiveRoles,
} from "slices/thunk";
// import { getDepartments as onGetDepartments } from "services/api_service";
import { ToastContainer } from "react-toastify";
import Pagination from "Common/Pagination";
import filterDataBySearch from 'Common/filterDataBySearch';

interface Option {
  readonly label: string;
  readonly value?: string;
  readonly options?: Option[];
  readonly isDisabled?: boolean
}

const AppraisalKpis = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (state) => ({
      dataList: state.appraisalKpis,
      dataDetails: state.appraisalKpiDetails,
      roleList: state.activeRoleslist,
    })
  );

  const roleValues: Option[] = [
    // { label: "Project-A", value: "Project-A" },
    // { label: "Project-B", value: "Project-B" },
  ];

  const { dataList, dataDetails, roleList} = useSelector(selectDataList);
  const [selectedRoles, selectRoles] = React.useState<readonly Option[]>([roleValues[0], roleValues[1]]);
  const [roles, setRole] = React.useState<readonly Option[]>([roleValues[0], roleValues[1]]);
  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [category, setCategory] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);    

  // Get Data
  useEffect(() => {
    dispatch(onGetActiveRoles());
    dispatch(onGetAppraisalKpis({ page: 1, per_page: 20, category: '', role: '', status: '' }));
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
    setRole(roleList?.data.map((role: any) => ({ label: role?.name, value: role?.name })));
    // console.log('validation values', validation.values);
  }, [dataList, roleList]);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteAppraisalKpi(eventData.id));
      setDeleteModal(false);
    }
  };
 
  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    selectRoles(ele.roles?.map((role: string) => ({ label: role, value: role }))); // Populate emails
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (eventData && eventData.name) || "",
      item: (eventData && eventData.item) || "",
      description: (eventData && eventData.description) || "",
      category: (eventData && eventData.category) || "",
      roles: (selectedRoles && selectedRoles?.map((role: any) => role?.value)) || [],
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Select Date"),
      item: Yup.string().required("Please Select Appraisal item"),
      category: Yup.string().required("Please Select category role"),
      roles: Yup.array(),
      description: Yup.string(),
      status: Yup.string()
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateAppraisalKpi(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new user
        dispatch(onAddAppraisalKpi(newData));
      }
       toggle();
    },
  });

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      selectRoles([]);
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      selectRoles([]);
      validation.resetForm();
    }
  }, [show, validation]);

  const categoyOptions = [
    { value: 'Select Status', label: 'Select Status' },
    { value: 'people_relations', label: 'People Relations' },
    { value: 'character_traits', label: 'Character Traits' },
    { value: 'effective_communication', label: 'Effective Communication' },
    { value: 'work_habits', label: 'Work Habits' },
    { value: 'business_support', label: 'Business Support' },
    { value: 'mi_research', label: 'Macro & Industry Research' },
    { value: 'ami_research', label: 'AMI Research' },
    { value: 'ratings', label: 'Ratings' },
    { value: 'training', label: 'Training' },
    { value: 'client_specific_report', label: 'Consulting/Client Specific Reports' }
];

  // Status
  const Status = ({ item }: any) => {
    switch (item) {
        case "active":
            return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
        case "pending":
            return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:text-zink-200 dark:border-transparent status">{item}</span>);
        case "inactive":
            return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status">{item}</span>);
        default:
            return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
    }
  };

  const Category = ({ item }: any) => {
    switch (item) {
        case "people_relations":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">People Relations</span>);
        case "character_traits":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Character Traits</span>);
        case "effective_communication":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Effective Communication</span>);
        case "work_habits":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Work Habits</span>);
        case "business_support":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Business Support</span>);
        case "mi_research":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Macro & Industry Research</span>);
        case "ami_research":
          return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">AMI Research</span>);
        case "ratings":
          return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Ratings</span>);
        case "training":
          return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Training</span>);
        case "client_specific_report":
          return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Consulting/Client Specific Reports</span>);
        default:
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">{item}</span>);
    }
  };

  const Roles = ({ item }: any ) => {
    <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:text-zink-200 dark:border-transparent status">{item}</span>
  }

  // columns
  const columns = useMemo(
    () => [
      {
        header: "#",
        // accessorKey: "id: index + 1",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex gap-2">
            {cell.row.index + 1}
          </div>
        ),
      },
      {
        header: "Category",
        accessorKey: "category",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
            <Category item={cell.getValue()} />
        ),
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Item",
        accessorKey: "item",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const maxLength = 50; // Define the max length
          const value = cell.getValue(); // Get the cell value
          return value.length > maxLength ? `${value.substring(0, maxLength)}...` : value;
        },
      },
      {
        header: "Roles",
        accessorKey: "roles",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => {
          const roles = cell.getValue(); // Get the array of roles
          return (
            <div className="flex flex-wrap gap-2">
              {roles && roles.map((role: string, index: number) => (
                <span
                  key={index}
                  className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-custom-100 border-transparent text-custom-500 dark:bg-custom-500/20 dark:text-zink-200 dark:border-transparent"
                >
                  {role}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
            <Status item={cell.getValue()} />
        ),
      },

      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-2">
            <Link
              to="#!"
              data-modal-target="addDepartmentModal"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/20"
              onClick={() => {
                const data = cell.row.original;
                handleUpdateDataClick(data);
              }}
            >
              <Pencil className="size-4" />
            </Link>
            <Link
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-red-500 dark:hover:text-red-500 hover:bg-red-100 dark:hover:bg-red-500/20"
              onClick={() => {
                const data = cell.row.original;
                onClickDelete(data);
              }}
            >
              <Trash2 className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ['username', 'employee_id', 'department_role', 'email', 'status'];
    filterDataBySearch(dataList?.data, search, keysToSearch, setData);
};


  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Status' || selectedOption.value === 'Hidden') {
      // onGetActiveRoles(userList);
        onGetActiveRoles();
    } else {
        // const filteredUsers = userList.filter((data: any) => data.status === selectedOption.value);
        // onGetActiveRoles(filteredUsers);
           onGetActiveRoles();
    }
};

  // Pagination
  const [currentPage, setCurrentPage] = useState<any>(1);

  const perPageData = 20;
  useEffect(() => {
    console.log('currentPage', currentPage);
    setCurrentPage(currentPage);
      // setData(currentdata);
      // handlePagination(currentPage);
      dispatch(onGetAppraisalKpis({ page: currentPage, per_page: perPageData, category: '', role: '', status: ''  }));
      
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <BreadCrumb title="Appraisal Kpi" pageTitle="Appraisal" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="card" id="ordersTable">

        
      <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
            <h6 className="text-15 grow">KPIs</h6>
            <div className="shrink-0">
              <Link
                to="#!"
                data-modal-target="addDepartmentModal"
                type="button"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                onClick={toggle}
              >
                <Plus className="inline-block size-4" />
                <span className="align-middle">Add New KPI</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
          <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                      <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for name, email, phone number etc..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                      <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                  <div className="xl:col-span-2">
                      <Select
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          options={categoyOptions}
                          isSearchable={false}
                          defaultValue={categoyOptions[0]}
                          onChange={(event: any) => handleChange(event)}
                          id="choices-single-default"
                      />
                  </div>
                  <div className="xl:col-span-2">
                      <Select
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          options={categoyOptions}
                          isSearchable={false}
                          defaultValue={categoyOptions[0]}
                          onChange={(event: any) => handleChange(event)}
                          id="choices-single-default"
                      />
                  </div>
                  <div className="xl:col-span-3 xl:col-start-10">
                      <div className="flex gap-2 xl:justify-end">
                          <div>
                              <button type="button" className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"><Download className="inline-block size-4" /> <span className="align-middle">Import</span></button>
                          </div>
                          {/* <button className="flex items-center justify-center size-[37.5px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"><SlidersHorizontal className="size-4" /></button> */}
                      </div>
                  </div>
              </div>
          </form>
        </div>

        <div className="card-body">
          {data && data.length > 0 ? (
            <TableContainer
              isPagination={false}
              columns={columns || []}
              data={data || []}
              customPageSize={perPageData}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right"
              thclassName="px-3.5 py-2.5 font-semibold border border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 border border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">
                  We've searched more than 5+ times We did not find any
                  appraisal category data for you search.
                </p>
              </div>
            </div>
          )}

        <Pagination
          perPageData={perPageData}
          data={dataList ? dataList?.data : dataList}
          total={dataList ? dataList?.meta?.total : 0}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currentdata={dataList? dataList?.data : []}
        />
        </div>
      </div>

      {/* Department Modal */}

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit Appraisal Kpi" : "Add Appraisal Kpi"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">

            <div className="xl:col-span-12">
              <label
                htmlFor="emailInput"
                className="inline-block mb-2 text-base font-medium"
              >
                Department Roles
              </label>

              <CreatableSelect
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="choices-text-unique-values"
                  data-choices
                  isMulti
                  name="roles"
          
                  onChange={(newRole: any) => selectRoles(newRole)}
                  value={selectedRoles}
                  options={[
                    { label: "All", value: "all" },
                    ...(Array.isArray(roles) ? roles : []) // Ensure roles is an array
                  ]}
                  // value={validation.values.email || ""}
              />
              {validation.touched.roles && validation.errors.roles ? (
                <p className="text-red-400">{validation.errors.roles}</p>
              ) : null}
            </div>
              
            <div className="xl:col-span-12">
                <label htmlFor="categoryInput" className="inline-block mb-2 text-base font-medium">Category</label>
                <select className="form-input border-slate-300 focus:outline-none focus:border-custom-500" data-choices data-choices-search-false 
                    id="categoryInput"
                    name="category"
                    onChange={validation.handleChange}
                    value={validation.values.category || ""}
                >
                    <option value="">Select Status</option>
                    <option value="people_relations">People Relations</option>
                    <option value="character_traits">Character Traits</option>
                    <option value="effective_communication">Effective Communication</option>
                    <option value="work_habits">Work Habits</option>
                    <option value="business_support">Business Support</option>
                    <option value="mi_research">Macro & Industry Research</option>
                    <option value="ami_research">AMI Research</option>
                    <option value="ratings">Ratings</option>
                    <option value="training">Training</option>
                    <option value="client_specific_report">Consulting/Client Specific Reports</option>
                </select>
                {validation.touched.category && validation.errors.status ? (
                    <p className="text-red-400">{validation.errors.category}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="nameInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="nameInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Kpi Name"
                  name="name"
                  onChange={validation.handleChange}
                  value={validation.values.name || ""}
                />
                {validation.touched.name && validation.errors.name ? (
                  <p className="text-red-400">{validation.errors.name}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="itemInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Item
                </label>
                <input
                  type="text"
                  id="itemInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Kpi Item"
                  name="item"
                  onChange={validation.handleChange}
                  value={validation.values.item || ""}
                />
                {validation.touched.item && validation.errors.item ? (
                  <p className="text-red-400">{validation.errors.item}</p>
                ) : null}
              </div>
             
              <div className="xl:col-span-12">
                <label
                  htmlFor="descriptionsInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Descriptions
                </label>
                <input
                  type="text"
                  id="descriptionsInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Descriptions"
                  name="description"
                  onChange={validation.handleChange}
                  value={validation.values.description || ""}
                />
                {validation.touched.description && validation.errors.description ? (
                  <p className="text-red-400">{validation.errors.description}</p>
                ) : null}
              </div>

              {isEdit && (
                <div className="xl:col-span-12">
                    <label htmlFor="statusInput" className="inline-block mb-2 text-base font-medium">Status</label>
                    <select className="form-input border-slate-300 focus:outline-none focus:border-custom-500" data-choices data-choices-search-false 
                        id="statusInput"
                        name="status"
                        onChange={validation.handleChange}
                        value={validation.values.status || ""}
                    >
                        <option value="">Select Status</option>
                        <option value="pending">Pending</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                    {validation.touched.status && validation.errors.status ? (
                        <p className="text-red-400">{validation.errors.status}</p>
                    ) : null}
                </div>
              )}

            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDepartmentModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Add Department"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AppraisalKpis;

