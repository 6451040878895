import React, { useEffect, useState } from "react";
import background from "../assets/images/landing/background.svg";
import overlay from "../assets/images/landing/overlay.svg";
import person from "../assets/images/landing/person.svg";
import memo from "../assets/images/landing/memo.svg";
import chart from "../assets/images/landing/chart.svg";
import pieChart from "../assets/images/landing/pieChart.svg";
import lock from "../assets/images/landing/lock.svg";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const updateBackground = () => {
      setShowBackground(window.innerWidth >= 768); // Apply background for medium screens and larger
    };

    updateBackground(); // Run on mount to set the initial state
    window.addEventListener("resize", updateBackground); // Listen for screen size changes

    return () => {
      window.removeEventListener("resize", updateBackground); // Clean up event listener
    };
  }, []);
  return (
    <main
      className="flex flex-col items-center justify-center h-full md:h-screen w-full"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <section className="mt-20 mb-10">
        <h2 className="text-center text-white text-3xl font-bold">
          Agusto & Co. Products{" "}
          <span className="opacity-70 font-normal">(Internal)</span>
        </h2>
      </section>
      <section
        className="max-w-5xl w-full px-4 rounded-xl flex justify-center items-center border-white lg:h-[60%] mb-20 md:mx-auto md:px-10 md:p-6 lg:px-4 lg:p-0"
        style={{
          backgroundImage: showBackground ? `url(${overlay})` : "none",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {/* w-[70%] h-[70%]  */}
        <section className="w-full max-w-lg grid grid-cols-1 md:grid-cols-2 gap-4 place-items-center justify-center items-center mx-auto">
          <Link
            to="/login"
            className="bg-[linear-gradient(to_right,_#068FA4,_#00B6BB)] rounded-lg w-[250px] h-[150px] flex justify-center "
          >
            <div className="text-center flex items-center justify-center flex-col p-4 ">
              <img src={person} alt="" className="w-16 md:w-20 h-auto" />
              <p className="leading-tight text-white">My Agusto Portal</p>
            </div>
          </Link>
          <Link
            to="https://arms.agusto.com"
            className="bg-[linear-gradient(to_right,_#068FA4,_#00B6BB)] rounded-lg w-[250px] h-[150px] flex justify-centerc"
          >
            <div className="text-center  flex items-center justify-center flex-col p-4">
              <img src={chart} alt="" className="w-16 md:w-20 h-auto" />
              <p className="leading-tight text-white">
                Agusto & Co. Rating Management
              </p>
            </div>
          </Link>
          <Link
            to="https://crms.ag-apps.agusto.com/"
            className=" bg-[linear-gradient(to_right,_#068FA4,_#00B6BB)] rounded-lg w-[250px] h-[150px] flex justify-center items-center"
          >
            <div className="text-center flex items-center justify-center flex-col">
              <img src={pieChart} alt="" className="w-16 md:w-20 h-auto" />
              <p className="leading-tight text-white">CRMA (Analyst Access)</p>
            </div>
          </Link>
          <Link
            to="#"
            className="cursor-not-allowed bg-[linear-gradient(to_right,_#068FA4,_#00B6BB)] rounded-lg w-[250px] h-[150px] flex justify-center items-center opacity-60 relative"
          >
            <img
              src={lock}
              alt=""
              className="absolute top-2 right-5 opacity-100"
            />
            <div className="text-center flex items-center justify-center flex-col">
              <img src={memo} alt="" className="w-16 md:w-20 h-auto" />
              <p className="leading-tight text-white">
                Memo, Voucher & Invoice Management
              </p>
            </div>
          </Link>
        </section>
      </section>
    </main>
  );
};

export default LandingPage;
