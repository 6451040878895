import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import Flatpickr from 'react-flatpickr';
import { ToastContainer } from "react-toastify";
import { Link, useParams } from 'react-router-dom';

import AccountInfo from "./AccountInfo";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import OverviewTabs from "./OverviewTabs";
import Documents from "./Documents";
import ProjectsTabs from "./ProjectsTabs";
// import Followers from "./Followers";

// react-redux
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import filterDataBySearch from 'Common/filterDataBySearch';
import {
    getUserList as onGetUserList,
    getUserDetails as ongetUserDetails,
    getUserProfile as onGetUserProfile,
    updateUserList as onUpdateUserList,
    deleteUserList as onDeleteUserList
} from 'slices/users/thunk';

const Account = () => {

    document.title = "Account";

    const { id } = useParams();  // Get the employee ID from params
    
    const dispatch = useDispatch<any>();

    // Get Data

    useEffect(() => {
        // console.log("Employee ID:", id);
        dispatch(ongetUserDetails(id));
    }, [dispatch, id]);

    return (
        <React.Fragment>
            <BreadCrumb title='Employee Details' pageTitle='User' />
            <ToastContainer closeButton={false} limit={1} />
            <Tab.Container defaultActiveKey="overviewTabs">
                <div className="mt-1 -ml-3 -mr-3 rounded-none card">
                    <AccountInfo  className="card-body !px-2.5"/>
                    <div className="card-body !px-2.5 !py-0">
                        <Nav className="flex flex-wrap w-full text-sm font-medium text-center nav-tabs">
                            <Nav.Item eventKey="overviewTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="overviewTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Overview</a>
                            </Nav.Item>
                            <Nav.Item eventKey="documentsTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="documentsTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Documents</a>
                            </Nav.Item>
                            <Nav.Item eventKey="projectsTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="projectsTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Tasks</a>
                            </Nav.Item>
                            <Nav.Item eventKey="followersTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="followersTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Leaves</a>
                            </Nav.Item>
                            <Nav.Item eventKey="followersTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="followersTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Appraisals</a>
                            </Nav.Item>
                            <Nav.Item eventKey="followersTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="followersTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">WFH</a>
                            </Nav.Item>

                            <Nav.Item eventKey="followersTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="followersTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Awards</a>
                            </Nav.Item>

                            <Nav.Item eventKey="followersTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="followersTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Trainings</a>
                            </Nav.Item>
                        </Nav>
                    </div>
                </div>
                <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="overviewTabs" id="overviewTabs">
                        <OverviewTabs />
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="documentsTabs" id="documentsTabs">
                        <Documents />
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="projectsTabs" id="projectsTabs">
                        <ProjectsTabs />
                    </Tab.Pane>
                    <Tab.Pane eventKey="followersTabs" id="followersTabs">
                        <></>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </React.Fragment>
    );
}

export default Account;