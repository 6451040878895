import React, { useState, useEffect, ChangeEvent } from "react";

// Formik Validation
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import userProfile from "assets/images/users/user-profile.png";

import { createSelector } from "reselect";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { editProfile } from "slices/thunk";
import avatar1 from "assets/images/users/avatar-1.png";
import { ToastContainer } from "react-toastify";
import Tab from "Common/Components/Tab/Tab";
import AccountInfo from "pages/Admin/Users/Account/AccountInfo";
import { Nav } from "Common/Components/Tab/Nav";
import OverviewTabs from "pages/Admin/Users/Account/OverviewTabs";

// import OverviewTabs from "./OverviewTabs";
// import Documents from "./Documents";
// import ProjectsTabs from "./ProjectsTabs";
// import Followers from "./Followers";
import { getUserDetails as ongetUserDetails } from "slices/users/thunk";

const UserProfile = () => {
  //meta title
  document.title = "Profile";
  // Get the employee ID from params

  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.Users,
    (hrState: any) => hrState.HRManagment,
    (state, hrState) => ({
      dataList: state.userProfile,
      checkAppraisal: hrState.checkAppraisalCategories,
    })
  );
  const { dataList, checkAppraisal } = useSelector(selectDataList);
  const [user, setData] = useState<any>({});
  const [appraisal, setAppraisal] = useState<any>({});


  useEffect(() => {
    setData(dataList?.data);
    setAppraisal(checkAppraisal?.data);
  }, [dataList, checkAppraisal]);

  useEffect(() => {
    dispatch(ongetUserDetails(user?.id));
  }, [dispatch, user?.id]);

  const [selectedImage, setSelectedImage] = React.useState<
    string | ArrayBuffer | null
  >(avatar1);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb title="User Profile" pageTitle="Users" />
      <ToastContainer closeButton={false} limit={1} />
      <Tab.Container defaultActiveKey="overviewTabs">
        <div className="mt-1 -ml-3 -mr-3 rounded-none card">
          <AccountInfo className="card-body !px-2.5" />
          <div className="card-body !px-2.5 !py-0">
            <Nav className="flex flex-wrap w-full text-sm font-medium text-center nav-tabs">
              <Nav.Item eventKey="overviewTabs" className="group">
                <a
                  href="#!"
                  data-tab-toggle
                  data-target="overviewTabs"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  Overview
                </a>
              </Nav.Item>
              {/* <Nav.Item eventKey="documentsTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="documentsTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Documents</a>
                            </Nav.Item> */}
              <Nav.Item eventKey="projectsTabs" className="group">
                <a
                  href="#!"
                  data-tab-toggle
                  data-target="projectsTabs"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  Tasks
                </a>
              </Nav.Item>
              <Nav.Item eventKey="leaveTabs" className="group">
                <a
                  href="#!"
                  data-tab-toggle
                  data-target="leaveTabs"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  Leave
                </a>
              </Nav.Item>
              <Nav.Item eventKey="appraisalTabs" className="group">
                <a
                  href="#!"
                  data-tab-toggle
                  data-target="appraisalTabs"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  Appraisal
                </a>
              </Nav.Item>
              <Nav.Item eventKey="wfhTabs" className="group">
                <a
                  href="#!"
                  data-tab-toggle
                  data-target="wfhTabs"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  WFH
                </a>
              </Nav.Item>

              {/* <Nav.Item eventKey="followersTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="followersTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Awards</a>
                            </Nav.Item>

                            <Nav.Item eventKey="followersTabs" className="group">
                                <a href="#!" data-tab-toggle data-target="followersTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Trainings</a>
                            </Nav.Item> */}
            </Nav>
          </div>
        </div>
        <Tab.Content className="tab-content">
          <Tab.Pane eventKey="overviewTabs" id="overviewTabs">
            <OverviewTabs />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
