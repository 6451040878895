import { createSlice } from "@reduxjs/toolkit";
import {
  getRoles,
  getActiveRoles,
  getRoleDetails,
  getDepartments,
  getActiveDepartments,
  getPositions,
  getPositionDetails,
  getActivePositions,
  getUserCategory,
  getActiveUserCategory,
  getUserCategoryDetails,
  deleteUserCategory,
  getAppraisalCategories,
  getAppraisalCategoryDetails,
  getActiveAppraisalCategories,
  checkOpenedAppraisalCategories,
  getAppraisalSupervisorMetrix,
  getDashboardAdminRequests,
  getAppraisalKpis,
  getAppraisalKpiDetails,
  getActiveAppraisalKpis,
  getAppraisals,
  getUserAppraisals,
  getAppraisalDetails,
  getActiveAppraisals,
  getColleagueAppraisals,
  getSupervisorAppraisals,
  getAppraisalSupervisorDashboard,
  getUserColleagueRelations,
  getColleagueRelationsDetails,
  getAppraisalPerformance,
  getTeamLeadPerformances,
  getAppraisalParameter,

  getWfh,
  getWfhDetails,
  getActiveWfh,
  getWfhBoard,
  getUserWfh,
  getSupervisorWfh,
  getWfhSupervisorDashboard,

  getAllTask,
  getTaskDetails,
  getActiveTask,
  getTaskBoard,
  getTaskMetrix,
  getUserTask,
  getDepartmentalTask,

  getLeaveTypes,
  getLeaveTypeDetails,
  getActiveLeaveTypes,

  getLeaveApplications,
  getUserLeaveApplications,
  getSupervisorLeaveMetrix,
  getSupervisorLeaveApplications,
  getLeaveSupervisorDashboard,
  getLeaveApplicationDetails,
  getActiveLeaveApplications,

  getEmployee,
  addEmployee,
  updateEmployee,
  deleteEmployee,
  getHolidays,
  addHolidays,
  updateHolidays,
  deleteHolidays,
  getEstimates,
  addEstimates,
  updateEstimates,
  deleteEstimates,
  getExpenses,
  addExpenses,
  updateExpenses,
  deleteExpenses,
  getLeaveManageHR,
  addLeaveManageHR,
  updateLeaveManageHR,
  deleteLeaveManageHR,
  getEmployeeSalary,
  addEmployeeSalary,
  updateEmployeeSalary,
  deleteEmployeeSalary,
  getAttendance,
  getMainAttendance,
  getLeaveManageEmployee,
  getPayments,
} from "./thunk";

export const initialState = {
  employeelist: [],
  holidayslist: [],
  departmentslist: [],
  estimateslist: [],
  expenseslist: [],
  leaveManageHRlist: [],
  employeeSalarylist: [],
  attendancelist: [],
  mainAttendancelist: [],
  leaveManageEmployeelist: [],
  paymentslist: [],
  errors: {},
};

const HRManagementSlice = createSlice({
  name: "HRManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //Dashobard
    builder.addCase(
      getDashboardAdminRequests.fulfilled,
      (state: any, action: any) => {
        state.dashboardAdminRequests = action.payload;
      }
    );

    // Roles
    builder.addCase(getRoles.fulfilled, (state: any, action: any) => {
      state.roleslist = action.payload;
    });
    builder.addCase(getActiveRoles.fulfilled, (state: any, action: any) => {
      state.activeRoleslist = action.payload;
    });
    builder.addCase(getRoles.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(getRoleDetails.fulfilled, (state: any, action: any) => {
      state.roleDetails = action.payload;
    });
    builder.addCase(getRoleDetails.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    // builder.addCase(addRole.fulfilled, (state: any, action: any) => {
    //   state.roleslist.unshift(action.payload);
    // });
    // builder.addCase(addRole.rejected, (state: any, action: any) => {
    //   state.error = action.payload.error || null;
    // });
    // builder.addCase(updateRole.fulfilled, (state: any, action: any) => {
    //   state.roleslist = state.roleslist.map((roleslist: any) =>
    //     roleslist.id === action.payload.id
    //       ? { ...roleslist, ...action.payload }
    //       : roleslist
    //   );
    // });
    // builder.addCase(updateRole.rejected, (state: any, action: any) => {
    //   state.error = action.payload.error || null;
    // });

    // Departments
    builder.addCase(getDepartments.fulfilled, (state: any, action: any) => {
      state.departmentslist = action.payload;
    });
    builder.addCase(getDepartments.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(
      getActiveDepartments.fulfilled,
      (state: any, action: any) => {
        state.activedepartmentslist = action.payload;
      }
    );
    builder.addCase(
      getActiveDepartments.rejected,
      (state: any, action: any) => {
        state.error = action.payload.error || null;
      }
    );

    // Posions
    builder.addCase(getPositions.fulfilled, (state: any, action: any) => {
      state.positionlist = action.payload;
    });
    builder.addCase(getActivePositions.fulfilled, (state: any, action: any) => {
      state.activePositionlist = action.payload;
    });
    builder.addCase(getPositionDetails.fulfilled, (state: any, action: any) => {
      state.positionlist = action.payload;
    });
    builder.addCase(getPositionDetails.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    // User Category
    builder.addCase(getUserCategory.fulfilled, (state: any, action: any) => {
      state.userCategorylist = action.payload;
    });
    builder.addCase(
      getActiveUserCategory.fulfilled,
      (state: any, action: any) => {
        state.activeUserCategorylist = action.payload;
      }
    );

    builder.addCase(
      getUserCategoryDetails.fulfilled,
      (state: any, action: any) => {
        state.userCategorylist = action.payload;
      }
    );
    builder.addCase(
      getUserCategoryDetails.rejected,
      (state: any, action: any) => {
        state.error = action.payload.error || null;
      }
    );
    builder.addCase(deleteUserCategory.fulfilled, (state: any, action: any) => {
      state.userCategorylist = action.payload;
    });
    builder.addCase(deleteUserCategory.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    // Appraisal Category
    builder.addCase(getAppraisalCategories.fulfilled, (state: any, action: any) => {
      state.appraisalCategories = action.payload;
    });
    builder.addCase(getAppraisalCategoryDetails.fulfilled, (state: any, action: any) => {
      state.appraisalCategoryDetails = action.payload;
    });
    builder.addCase(getActiveAppraisalCategories.fulfilled, (state: any, action: any) => {
      state.acativeAppraisalCategories = action.payload;
    });
    builder.addCase(checkOpenedAppraisalCategories.fulfilled, (state: any, action: any) => {
      state.checkAppraisalCategories = action.payload;
    });

     // Appraisal KPi
     builder.addCase(getAppraisalKpis.fulfilled, (state: any, action: any) => {
      state.appraisalKpis = action.payload;
    });
    builder.addCase(getAppraisalKpiDetails.fulfilled, (state: any, action: any) => {
      state.appraisalKpiDetails = action.payload;
    });
    builder.addCase(getActiveAppraisalKpis.fulfilled, (state: any, action: any) => {
      state.acativeAppraisalKpis = action.payload;
    });

    // Appraisals
    builder.addCase(getAppraisals.fulfilled, (state: any, action: any) => {
      state.appraisals = action.payload;
    });
    builder.addCase(getUserAppraisals.fulfilled, (state: any, action: any) => {
      state.userAppraisals = action.payload;
    });
    builder.addCase(getColleagueAppraisals.fulfilled, (state: any, action: any) => {
      state.colleagueAppraisals = action.payload;
    });
    builder.addCase(getSupervisorAppraisals.fulfilled, (state: any, action: any) => {
      state.supervisorAppraisals = action.payload;
    });
    builder.addCase(getAppraisalSupervisorDashboard.fulfilled, (state: any, action: any) => {
      state.supervisorDashboardAppraisals = action.payload;
    });
    builder.addCase(getAppraisalDetails.fulfilled, (state: any, action: any) => {
      state.appraisalDetails = action.payload;
    });
    builder.addCase(getActiveAppraisals.fulfilled, (state: any, action: any) => {
      state.activeAppraisals = action.payload;
    });
    builder.addCase(getAppraisalSupervisorMetrix.fulfilled, (state: any, action: any) => {
      state.appraisalSupervisorMetrix = action.payload;
    });

    // Performance
    builder.addCase(getUserColleagueRelations.fulfilled, (state: any, action: any) => {
      state.userColleagueRelations = action.payload;
    });
    builder.addCase(getColleagueRelationsDetails.fulfilled, (state: any, action: any) => {
      state.colleagueRelationsDetails = action.payload;
    });
    builder.addCase(getAppraisalPerformance.fulfilled, (state: any, action: any) => {
      state.appraisalPerformance = action.payload;
    });
    builder.addCase(getTeamLeadPerformances.fulfilled, (state: any, action: any) => {
      state.teamLeadPerformances = action.payload;
    });
    builder.addCase(getAppraisalParameter.fulfilled, (state: any, action: any) => {
      state.appraisalParameter = action.payload;
    });

    // WFH
    builder.addCase(getWfh.fulfilled, (state: any, action: any) => {
      state.wfh = action.payload;
    });
    builder.addCase(getWfhDetails.fulfilled, (state: any, action: any) => {
      state.wfhDetails = action.payload;
    });
    builder.addCase(getWfhBoard.fulfilled, (state: any, action: any) => {
      state.wfhBoard = action.payload;
    });
    builder.addCase(getSupervisorWfh.fulfilled, (state: any, action: any) => {
      state.supervisorWfh = action.payload;
    });
    builder.addCase(getWfhSupervisorDashboard.fulfilled, (state: any, action: any) => {
      state.supervisorDashboardWfh = action.payload;
    });
    builder.addCase(getActiveWfh.fulfilled, (state: any, action: any) => {
      state.activeWfh = action.payload;
    });
    builder.addCase(getUserWfh.fulfilled, (state: any, action: any) => {
      state.userWfh = action.payload;
    });

    // WFH
    builder.addCase(getAllTask.fulfilled, (state: any, action: any) => {
      state.allTask = action.payload;
    });
    builder.addCase(getTaskDetails.fulfilled, (state: any, action: any) => {
      state.taskDetails = action.payload;
    });
    builder.addCase(getActiveTask.fulfilled, (state: any, action: any) => {
      state.activeTask = action.payload;
    });
    builder.addCase(getTaskBoard.fulfilled, (state: any, action: any) => {
      state.taskBoard = action.payload;
    });
    builder.addCase(getTaskMetrix.fulfilled, (state: any, action: any) => {
      state.taskMetrix = action.payload;
    });
    builder.addCase(getUserTask.fulfilled, (state: any, action: any) => {
      state.userTask = action.payload;
    });
    builder.addCase(getDepartmentalTask.fulfilled, (state: any, action: any) => {
      state.departmentalTask = action.payload;
    });
    
      // Leave Type
    builder.addCase(getLeaveTypes.fulfilled, (state: any, action: any) => {
      state.leaveTypes = action.payload;
    });
    builder.addCase(getLeaveTypeDetails.fulfilled, (state: any, action: any) => {
      state.leaveTypesDetails = action.payload;
    });
    builder.addCase(getActiveLeaveTypes.fulfilled, (state: any, action: any) => {
      state.activeLeaveTypes = action.payload;
    });

    // Leave Application
    builder.addCase(getLeaveApplications.fulfilled, (state: any, action: any) => {
      state.leaveApplications = action.payload;
    });
    builder.addCase(getUserLeaveApplications.fulfilled, (state: any, action: any) => {
      state.userLeaveApplications = action.payload;
    });
    builder.addCase(getSupervisorLeaveApplications.fulfilled, (state: any, action: any) => {
      state.supervisorLeaveApplications = action.payload;
    });
    builder.addCase(getLeaveSupervisorDashboard.fulfilled, (state: any, action: any) => {
      state.supervisorLeaveDashboard = action.payload;
    });
    builder.addCase(getSupervisorLeaveMetrix.fulfilled, (state: any, action: any) => {
      state.supervisorLeaveMetrix = action.payload;
    });
    builder.addCase(getLeaveApplicationDetails.fulfilled, (state: any, action: any) => {
      state.leaveApplicationDetails = action.payload;
    });
    builder.addCase(getActiveLeaveApplications.fulfilled, (state: any, action: any) => {
      state.activeLeaveApplications = action.payload;
    });


    // Employee
    builder.addCase(getEmployee.fulfilled, (state: any, action: any) => {
      state.employeelist = action.payload;
    });
    builder.addCase(getEmployee.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addEmployee.fulfilled, (state: any, action: any) => {
      state.employeelist.unshift(action.payload);
    });
    builder.addCase(addEmployee.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateEmployee.fulfilled, (state: any, action: any) => {
      state.employeelist = state.employeelist.map((employeelist: any) =>
        employeelist.id === action.payload.id
          ? { ...employeelist, ...action.payload }
          : employeelist
      );
    });
    builder.addCase(updateEmployee.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(deleteEmployee.fulfilled, (state: any, action: any) => {
      state.employeelist = state.employeelist.filter(
        (employeelist: any) =>
          employeelist.id.toString() !== action.payload.toString()
      );
    });
    builder.addCase(deleteEmployee.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    // Holidays
    builder.addCase(getHolidays.fulfilled, (state: any, action: any) => {
      state.holidayslist = action.payload;
    });
    builder.addCase(getHolidays.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addHolidays.fulfilled, (state: any, action: any) => {
      state.holidayslist.unshift(action.payload);
    });
    builder.addCase(addHolidays.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateHolidays.fulfilled, (state: any, action: any) => {
      state.holidayslist = state.holidayslist.map((holidayslist: any) =>
        holidayslist.id === action.payload.id
          ? { ...holidayslist, ...action.payload }
          : holidayslist
      );
    });

    builder.addCase(updateHolidays.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(deleteHolidays.fulfilled, (state: any, action: any) => {
      state.holidayslist = state.holidayslist.filter(
        (holidayslist: any) =>
          holidayslist.id.toString() !== action.payload.toString()
      );
    });
    builder.addCase(deleteHolidays.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    // Estimates
    builder.addCase(getEstimates.fulfilled, (state: any, action: any) => {
      state.estimateslist = action.payload;
    });
    builder.addCase(getEstimates.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addEstimates.fulfilled, (state: any, action: any) => {
      state.estimateslist.unshift(action.payload);
    });
    builder.addCase(addEstimates.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateEstimates.fulfilled, (state: any, action: any) => {
      state.estimateslist = state.estimateslist.map((estimateslist: any) =>
        estimateslist.id === action.payload.id
          ? { ...estimateslist, ...action.payload }
          : estimateslist
      );
    });
    builder.addCase(updateEstimates.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(deleteEstimates.fulfilled, (state: any, action: any) => {
      state.estimateslist = state.estimateslist.filter(
        (estimateslist: any) =>
          estimateslist.id.toString() !== action.payload.toString()
      );
    });
    builder.addCase(deleteEstimates.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    // Expenses
    builder.addCase(getExpenses.fulfilled, (state: any, action: any) => {
      state.expenseslist = action.payload;
    });
    builder.addCase(getExpenses.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addExpenses.fulfilled, (state: any, action: any) => {
      state.expenseslist.unshift(action.payload);
    });
    builder.addCase(addExpenses.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateExpenses.fulfilled, (state: any, action: any) => {
      state.expenseslist = state.expenseslist.map((expenseslist: any) =>
        expenseslist.id === action.payload.id
          ? { ...expenseslist, ...action.payload }
          : expenseslist
      );
    });
    builder.addCase(updateExpenses.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(deleteExpenses.fulfilled, (state: any, action: any) => {
      state.expenseslist = state.expenseslist.filter(
        (expenseslist: any) =>
          expenseslist.id.toString() !== action.payload.toString()
      );
    });
    builder.addCase(deleteExpenses.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    // Leave Manage HR
    builder.addCase(getLeaveManageHR.fulfilled, (state: any, action: any) => {
      state.leaveManageHRlist = action.payload;
    });
    builder.addCase(getLeaveManageHR.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addLeaveManageHR.fulfilled, (state: any, action: any) => {
      state.leaveManageHRlist.unshift(action.payload);
    });
    builder.addCase(addLeaveManageHR.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(
      updateLeaveManageHR.fulfilled,
      (state: any, action: any) => {
        state.leaveManageHRlist = state.leaveManageHRlist.map(
          (leaveManageHRlist: any) =>
            leaveManageHRlist.id === action.payload.id
              ? { ...leaveManageHRlist, ...action.payload }
              : leaveManageHRlist
        );
      }
    );
    builder.addCase(updateLeaveManageHR.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(
      deleteLeaveManageHR.fulfilled,
      (state: any, action: any) => {
        state.leaveManageHRlist = state.leaveManageHRlist.filter(
          (leaveManageHRlist: any) =>
            leaveManageHRlist.id.toString() !== action.payload.toString()
        );
      }
    );
    builder.addCase(deleteLeaveManageHR.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    // Employee Salary
    builder.addCase(getEmployeeSalary.fulfilled, (state: any, action: any) => {
      state.employeeSalarylist = action.payload;
    });
    builder.addCase(getEmployeeSalary.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addEmployeeSalary.fulfilled, (state: any, action: any) => {
      state.employeeSalarylist.unshift(action.payload);
    });
    builder.addCase(addEmployeeSalary.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(
      updateEmployeeSalary.fulfilled,
      (state: any, action: any) => {
        state.employeeSalarylist = state.employeeSalarylist.map(
          (employeeSalarylist: any) =>
            employeeSalarylist.id === action.payload.id
              ? { ...employeeSalarylist, ...action.payload }
              : employeeSalarylist
        );
      }
    );
    builder.addCase(
      updateEmployeeSalary.rejected,
      (state: any, action: any) => {
        state.error = action.payload.error || null;
      }
    );
    builder.addCase(
      deleteEmployeeSalary.fulfilled,
      (state: any, action: any) => {
        state.employeeSalarylist = state.employeeSalarylist.filter(
          (employeeSalarylist: any) =>
            employeeSalarylist.id.toString() !== action.payload.toString()
        );
      }
    );
    builder.addCase(
      deleteEmployeeSalary.rejected,
      (state: any, action: any) => {
        state.error = action.payload.error || null;
      }
    );

    // Attendance
    // Attendance (HR)
    builder.addCase(getAttendance.fulfilled, (state: any, action: any) => {
      state.attendancelist = action.payload;
    });
    builder.addCase(getAttendance.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    // Main Attendance
    builder.addCase(getMainAttendance.fulfilled, (state: any, action: any) => {
      state.mainAttendancelist = action.payload;
    });
    builder.addCase(getMainAttendance.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    // Leave Manage (Employee)
    builder.addCase(
      getLeaveManageEmployee.fulfilled,
      (state: any, action: any) => {
        state.leaveManageEmployeelist = action.payload;
      }
    );
    builder.addCase(
      getLeaveManageEmployee.rejected,
      (state: any, action: any) => {
        state.error = action.payload.error || null;
      }
    );

    // Payments
    builder.addCase(getPayments.fulfilled, (state: any, action: any) => {
      state.paymentslist = action.payload;
    });
    builder.addCase(getPayments.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export default HRManagementSlice.reducer;
