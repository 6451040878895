import React, { useEffect, useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import microsoft from "assets/images/auth/microsoft.svg";

// import { Facebook, Github, Mail, Smartphone, Twitter } from "lucide-react";

// Formik validation
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";

import Tab from "Common/Components/Tab/Tab";
import { Nav } from "Common/Components/Tab/Nav";

// Image
import logoLight from "assets/images/logo-light.png";
import logoDark from "assets/images/logo-dark.png";
import { loginUser } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
// Image
import hr from "assets/images/auth/login-image.svg";
import logo from "assets/images/auth/auth-icon.png";
import modern from "assets/images/auth-modern.png";

import { Link } from "react-router-dom";

const Login = (props: any) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };
  document.title = "Sign In | Agusto Portal";

  const dispatch = useDispatch<any>();

  const selectLogin = createSelector(
    (state: any) => state.Register,
    (state: any) => state.Login,
    (register, login) => ({
      user: register.user,
      success: login.success,
      error: login.error,
    })
  );

  const { user, success, error } = useSelector(selectLogin);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700); // adjust the breakpoint as needed

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900); // adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const validation: any = useFormic({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: user.email || "",
      password: user.password || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  React.useEffect(() => {
    const bodyElement = document.body;

    bodyElement.classList.add("font-public");

    return () => {
      bodyElement.classList.remove("font-public");
    };
  }, []);

  return (
    <React.Fragment>
      <div className="relative flex flex-col w-full overflow-hidden md:flex-row to-custom-800 from-custom-900 dark:to-custom-900 dark:from-custom-950">
        {/* <ModernImage /> */}

        {/* Conditionally render the background div */}
        {!isSmallScreen && (
          <div
            className="min-h-[calc(100vh_-_theme('spacing.4')_*_2)] mx-3 lg:w-[40rem] shrink-0 px-10 py-14 flex items-center justify-center m-4 bg-white rounded z-10 relative dark:bg-zink-700 dark:text-zink-100 md:mx-auto xl:mx-6"
            style={{
              backgroundImage: `url(${hr})`,
              width: "55%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "2rem", // Add borderRadius here
              overflow: "hidden", // Add overflow here
            }}
          ></div>
        )}

        <div className="relative z-10 flex items-center justify-center min-h-screen px-10 grow py-14">
          <div className="flex flex-col w-full h-full">
            {/* <AuthDropdown /> */}
            <div className="my-auto">
              <Tab.Container defaultActiveKey="emailLogin">
                <Link to="/">
                  <img src={logo} alt="" className="block mx-auto mb-14 h-20" />
                </Link>

                <div className="lg:w-[20rem] mx-auto mt-10 text-[#031F37]">
                  <div className="mt-10 text-center">
                    <h3 className="mt-4 mb-3 capitalize text-custom-20">
                      Welcome!
                    </h3>
                    <p className="max-w-2xl text-custom-25 text-16">
                      Please sign in with your credentials
                    </p>
                  </div>
                </div>
                <div className="lg:w-[25rem] mx-auto">
                  <Tab.Content className="mt-5 tab-content">
                    <Tab.Pane
                      eventKey="emailLogin"
                      className="tab-pane"
                      id="emailLogin"
                    >
                      <form
                        className="mt-10"
                        id="signInForm1"
                        onSubmit={(event: any) => {
                          event.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {success && (
                          <div
                            className="px-4 py-3 mb-5 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50"
                            id="successAlert"
                          >
                            You have <b>successfully</b> signed in.
                          </div>
                        )}
                        {error && (
                          <div
                            className="px-4 py-3 mb-5 text-sm text-red-500 border border-red-200 rounded-md bg-red-50 dark:bg-red-400/20 dark:border-red-500/50"
                            id="successAlert"
                          >
                            Sign in <b>failed</b>.
                          </div>
                        )}
                        <div className="relative mb-4">
                          <input
                            type="text"
                            id="email"
                            name="email"
                            className="form-input peer w-full borderform border-gray-300 rounded-lg px-4 pt-5 pb-3 text-sm text-gray-900 bg-slate-100 focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
                            placeholder=""
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <div
                              id="email-error"
                              className="mt-1 text-sm text-red-500"
                            >
                              {validation.errors.email}
                            </div>
                          ) : null}
                          <label
                            htmlFor="email"
                            className="absolute left-4 text-base -top-2.5 text-gray-500 transition-all bg-white px-1 peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:font-bold peer-focus:text-blue-500"
                          >
                            Email
                          </label>
                        </div>

                        <div className="relative mb-3">
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            id="password"
                            name="password"
                            // form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200
                            className="form-input peer w-full borderform border-gray-300 rounded-lg px-4 pt-5 pb-3 text-sm text-gray-900 bg-slate-100 focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
                            placeholder=""
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <div
                              id="password-error"
                              className="mt-1 text-sm text-red-500"
                            >
                              {validation.errors.password}
                            </div>
                          ) : null}
                          <label
                            htmlFor="password"
                            // className="inline-block mb-2 text-base font-medium"
                            className="absolute left-4 -top-2.5 text-gray-500 text-base transition-all bg-white px-1 peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:font-bold peer-focus:text-blue-500"
                          >
                            Password
                          </label>
                          {/* Eye icon to toggle visibility */}
                          <button
                            type="button"
                            className="absolute right-4 top-1/2 transform -translate-y-1/2"
                            onClick={togglePasswordVisibility}
                          >
                            {isPasswordVisible ? (
                              <Eye size={15} className="text-gray-500" />
                            ) : (
                              <EyeOff size={15} className="text-gray-500" />
                            )}
                          </button>
                        </div>
                        <div>
                          <div className="flex items-center gap-4 mt-2">
                            <input
                              id="checkboxDefault1"
                              className="size-4 border rounded-sm appearance-none bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500 checked:disabled:bg-custom-400 checked:disabled:border-custom-400"
                              type="checkbox"
                              value=""
                            />
                            <label
                              htmlFor="checkboxDefault1"
                              className="inline-block text-base font-medium align-middle cursor-pointer"
                            >
                              Remember me
                            </label>
                          </div>
                          {/* <div id="remember-error" className="hidden mt-1 text-sm text-red-500">Please check the "Remember me" before submitting the form.</div> */}
                        </div>
                        <div className="mt-10">
                          <button
                            type="submit"
                            className="w-full uppercase font-bold py-3 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                          >
                            Sign In
                          </button>
                        </div>
                        <div className="flex flex-col items-center text-center justify-center mt-4 gap-2">
                          <p className="text-slate-500">Or continue with</p>
                          <button
                            className="flex items-center justify-center"
                            disabled={true}
                          >
                            <img
                              src={microsoft}
                              alt="microsoft"
                              className="w-[80%]"
                            />
                          </button>
                        </div>
                      </form>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="phoneLogin"
                      className="tab-pane"
                      id="phoneLogin"
                    >
                      <form
                        className="mt-10"
                        id="signInForm2"
                        onSubmit={(event: any) => {
                          event.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {success && (
                          <div
                            className="px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50"
                            id="successAlert"
                          >
                            You have <b>successfully</b> signed in.
                          </div>
                        )}
                        {error && (
                          <div
                            className="px-4 py-3 mb-3 text-sm text-red-500 border border-red-200 rounded-md bg-red-50 dark:bg-red-400/20 dark:border-red-500/50"
                            id="successAlert"
                          >
                            You have <b>failed</b> signed in.
                          </div>
                        )}
                        <div className="mb-3">
                          <label
                            htmlFor="email"
                            className="inline-block mb-2 text-base font-medium"
                          >
                            UserName/ Email ID
                          </label>
                          <input
                            type="text"
                            id="email"
                            name="email"
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            placeholder="Enter username or email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <div
                              id="email-error"
                              className="mt-1 text-sm text-red-500"
                            >
                              {validation.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="password"
                            className="inline-block mb-2 text-base font-medium"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            id="password"
                            name="password"
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            placeholder="Enter password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <div
                              id="password-error"
                              className="mt-1 text-sm text-red-500"
                            >
                              {validation.errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <div className="flex items-center gap-2 mt-4">
                            <input
                              id="checkboxDefault1"
                              className="size-4 border rounded-sm appearance-none bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500 checked:disabled:bg-custom-400 checked:disabled:border-custom-400"
                              type="checkbox"
                              value=""
                            />
                            <label
                              htmlFor="checkboxDefault1"
                              className="inline-block text-base font-medium align-middle cursor-pointer"
                            >
                              Remember me
                            </label>
                          </div>
                          {/* <div id="remember-error" className="hidden mt-1 text-sm text-red-500">Please check the "Remember me" before submitting the form.</div> */}
                        </div>
                        <div className="mt-10">
                          <button
                            type="submit"
                            className="w-full uppercase font-bold  text-white btn bg-custom-600  hover:text-white hover:bg-custom-500 hover:border-custom-500 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                    </Tab.Pane>
                  </Tab.Content>
                  {/* <div className="relative text-center my-9 before:absolute before:top-3 before:left-0 before:right-0 before:border-t before:border-t-slate-200 dark:before:border-t-zink-500">
                                        <h5 className="inline-block px-4 py-0.5 text-sm bg-white text-slate-500 dark:bg-zink-700 dark:text-zink-200 rounded relative">Sign In with</h5>
                                    </div>

                                    <div className="flex flex-wrap justify-center gap-2">
                                        <button type="button" className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-custom-500 btn bg-custom-100 border-custom-100 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 active:text-white active:bg-custom-600 active:border-custom-600">
                                            <Facebook className="size-4"></Facebook>
                                        </button>
                                        <button type="button" className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-orange-500 btn bg-orange-100 border-orange-100 hover:text-white hover:bg-orange-600 hover:border-orange-600 focus:text-white focus:bg-orange-600 focus:border-orange-600 active:text-white active:bg-orange-600 active:border-orange-600">
                                            <Mail className="size-4"></Mail>
                                        </button>
                                        <button type="button" className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-sky-500 btn bg-sky-100 border-sky-100 hover:text-white hover:bg-sky-600 hover:border-sky-600 focus:text-white focus:bg-sky-600 focus:border-sky-600 active:text-white active:bg-sky-600 active:border-sky-600">
                                            <Twitter className="size-4"></Twitter>
                                        </button>
                                        <button type="button" className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-slate-500 btn bg-slate-200 border-slate-200 hover:text-white hover:bg-slate-600 hover:border-slate-600 focus:text-white focus:bg-slate-600 focus:border-slate-600 active:text-white active:bg-slate-600 active:border-slate-600">
                                            <Github className="size-4"></Github>
                                        </button>
                                    </div>

                                    <div className="mt-10 text-center">
                                        <p className="mb-0 text-slate-500 dark:text-zink-200">Don't have an account ? <Link to="/auth-register-modern" className="font-semibold underline transition-all duration-150 ease-linear text-slate-500 dark:text-zink-200 hover:text-custom-500"> SignUp</Link> </p>
                                    </div> */}
                </div>
              </Tab.Container>
            </div>
            <div className="mt-5">
              <p className="mb-0 text-center text-15 text-slate-500 dark:text-zink-200">
                © {new Date().getFullYear()}{" "}
                <a
                  href="https://www.agusto.com"
                  target="_blank"
                  className="underline transition-all duration-200 ease-linear text-slate-800 dark:text-zink-50 hover:text-custom-500"
                  rel="noreferrer"
                >
                  Agusto & Co.
                </a>{" "}
                All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
