import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import TableContainer from "Common/TableContainer";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import Select from "react-select";

// Icons
import {
  Search,
  Check,
  X,
  Clock,
  XOctagon,
  RefreshCw,
  CheckCheck,
  Loader,
  Plus,
} from "lucide-react";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getSupervisorWfh as onGetSupervisorWfh,
  getWfh as onGetWfh,
  getActiveUsers as onGetUserList,
  getWfhDetails as onGetWfhDetails,
  getWfhBoard as onGetWfhBoard,
  addWfh as onAddWfh,
  updateWfh as onUpdateWfh,
  deleteWfh as onDeleteWfh,
  rejectWfh as onRejectWfh,
} from "slices/thunk";
import filterDataBySearch from "Common/filterDataBySearch";
import { ToastContainer } from "react-toastify";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

const WFHRequest = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (user: any) => user.Users,
    (state, user) => ({
      dataList: state.supervisorWfh,
      userList: user.activeUsers
    })
  );

  const { dataList, userList} = useSelector(selectDataList);
  const [data, setData] = useState<any>([]);
  const [users, setUser] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({ min: "", max: "" });
  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Get Data
  useEffect(() => {
    dispatch(onGetSupervisorWfh());
    dispatch(onGetUserList());
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
    setUser(userList?.data);
  }, [dataList, userList]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = [
      "day",
      "date",
      "checkIn",
      "checkOut",
      "mealBreak",
      "workHours",
      "overtime",
    ];
    filterDataBySearch(dataList, search, keysToSearch, setData);
  };

  const columns = useMemo(
    () => [
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <React.Fragment>
            {cell.getValue()}{" "}
            <span className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-white border-slate-400 text-slate-500 dark:bg-zink-700 dark:border-zink-400 dark:text-zink-200 ltr:ml-1 rtl:mr-1 align-middle">
              {cell.row.original.day}
            </span>
          </React.Fragment>
        ),
      },
      {
        header: "Staff",
        accessorKey: "user_name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.user_image ? (
                <img
                  src={cell.row.original.user_image}
                  alt=""
                  className="h-10 rounded-full"
                />
              ) : (
                ""
              )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.user_role}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <div className="flex gap-2">
            {/* <Link to="#!" className="flex items-center justify-center size-8 text-green-500 transition-all duration-200 ease-linear bg-green-100 rounded-md hover:text-white hover:bg-green-500 dark:bg-green-500/20 dark:hover:bg-green-500"><Check className="size-4" /></Link> */}
            <Link
              to="#!"
              className="flex items-center justify-center size-8 text-red-500 transition-all duration-200 ease-linear bg-red-100 rounded-md hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
              onClick={() => {
                const data = cell.row.original;
                onClickDelete(data);
              }}
            >
              <X className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onRejectWfh(eventData.id));
      setDeleteModal(false);
    }
  };
  //

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      date:
        (eventData && new Date(eventData.date).toISOString().split("T")[0]) ||
        "",
      comment: (eventData && eventData.comment) || "",
      status: (eventData && eventData.status) || "",
      user_id: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      date: Yup.string().required("Please select your work from home date"),
      comment: Yup.string(),
      status: Yup.string(),
      user_id: Yup.string(),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateWfh(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new user
        dispatch(onAddWfh(newData));
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  // Status
  const Status = ({ item }: any) => {
    switch (item) {
      case "approved":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            {item}
          </span>
        );
      case "pending":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
            {item}
          </span>
        );
      case "declined":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status">
            {item}
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            {item}
          </span>
        );
    }
  };
  const approvedCount = data?.filter(
    (item: any) => item.status === "approved"
  ).length;
  const declinedCount = data?.filter(
    (item: any) => item.status === "declined"
  ).length;

  return (
    <React.Fragment>
      <BreadCrumb title="WFH Request" pageTitle="Work From Home" />
      <ToastContainer closeButton={false} limit={1} />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <div className="grid grid-cols-1 lg:grid-cols-12 xl:grid-cols-12 gap-x-5">
        <div className="lg:col-span-4 xl:col-span-4">
          <div className="card">
            <div className="flex items-center gap-4 card-body bg-agusto-700  rounded-md">
              <div className="flex items-center justify-center size-12 rounded-md text-white bg-agusto-750 text-15  shrink-0">
                <Clock />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={data?.length}
                    className="counter-value text-white"
                  />
                </h5>
                <p className="text-white">Total WFH</p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-4 xl:col-span-4">
          <div className="card">
            <div className="flex items-center gap-4 card-body bg-agusto-800 ">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-850 rounded-md text-15 shrink-0">
                <CheckCheck />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16 text-white">
                  <CountUp end={approvedCount} className="counter-value" />
                </h5>
                <p className="text-white">Approved WFH</p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-4 xl:col-span-4">
          <div className="card">
            <div className="flex items-center gap-4 card-body bg-agusto-900 ">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-950 rounded-md text-15  shrink-0">
                <Loader />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16 text-white">
                  <CountUp end={declinedCount} className="counter-value" />
                </h5>
                <p className="text-white">Declined WFH</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="lg:col-span-4 xl:col-span-3">
                    <div className="card">
                        <div className="flex items-center gap-4 card-body">
                            <div className="flex items-center justify-center size-12 text-yellow-500 bg-yellow-100 rounded-md text-15 dark:bg-yellow-500/20 shrink-0"><RefreshCw /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={0} className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Requested WFH</p>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="xl:col-span-12 lg:col-span-12">
          <div className="card">
            <div className="card-body">
              <div className="grid grid-cols-1 gap-4 mb-5 lg:grid-cols-2 xl:grid-cols-12">
                <div className="xl:col-span-3">
                  <div className="relative">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search for ..."
                      autoComplete="off"
                      onChange={(e) => filterSearchData(e)}
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                </div>
                <div className="xl:col-span-3">
                  <div>
                    <Flatpickr
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      options={{
                        dateFormat: "d M, Y",
                        mode: "range",
                      }}
                      placeholder="Select date"
                    />
                  </div>
                </div>

                <div className="shrink-0">
                {/* <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={toggle}><Plus className="inline-block size-4" /> <span className="align-middle">Add User</span></button> */}
                
              </div>

                <div className="flex justify-end gap-2 text-right lg:col-span-2 xl:col-span-4 xl:col-start-10">

                <Link
                    to="#!"
                    onClick={toggle}
                    type="button"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    <Plus className="inline-block size-4" />{" "}
                    <span className="align-middle">Create User WFH</span>
                  </Link>
                  
                  {/* <Link to="#!" type="button" className="text-red-500 bg-white border-red-500 border-dashed btn hover:text-red-500 hover:bg-red-50 hover:border-red-600 focus:text-red-600 focus:bg-red-50 focus:border-red-600 active:text-red-600 active:bg-red-50 active:border-red-600 dark:bg-zink-700 dark:ring-red-400/20 dark:hover:bg-red-800/20 dark:focus:bg-red-800/20 dark:active:bg-red-800/20">Rejecte All</Link> */}
                  {/* <Link to="#!" type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Create WFH</Link>
                                    <Link to="#!" type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Approve All</Link> */}
                </div>
              </div>
              {data && data.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={data || []}
                  customPageSize={10}
                  divclassName="overflow-x-auto"
                  tableclassName="w-full whitespace-nowrap"
                  theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                  thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                  tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                  PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                      We've searched more than 10+ Attendance We did not find
                      any Attendance for you search.
                    </p> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Update WFH" : "Create New WFH"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">

              <div className="xl:col-span-12">
                <label
                  htmlFor="user_idInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Staff
                </label>

                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  id="user_idInput"
                  name="user_id"
                  onChange={validation.handleChange}
                  value={validation.values.user_id || ""}
                >
                  <option value="">Select Staff</option>
                  {users?.map((user: any) => (
                    <option key={user?.id} value={user?.id}>
                      {user?.username}
                    </option>
                  ))}
                </select>
                {validation.touched.user_id &&
                validation.errors.user_id ? (
                  <p className="text-red-400">
                    {validation.errors.user_id}
                  </p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="dateInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Select Date
                </label>
                <input
                  type="date"
                  id="dateInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="User Category name"
                  name="date"
                  onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    const dayOfWeek = selectedDate.getDay();
                
                    // If the selected date is Tuesday (2) or Wednesday (3), reset the value
                    if (dayOfWeek === 2 || dayOfWeek === 3) {
                      alert("Please select a Monday, Thursday, or Friday.");
                      e.target.value = "";
                    } else {
                      validation.handleChange(e);
                    }
                  }}
                  value={validation.values.date || ""}
                  // min={dateRange.min}
                  // max={dateRange.max}
                />
                {validation.touched.date && validation.errors.date ? (
                  <p className="text-red-400">{validation.errors.date}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="commentInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Comment
                </label>
                <input
                  type="text"
                  id="commentInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Description"
                  name="comment"
                  onChange={validation.handleChange}
                  value={validation.values.comment || ""}
                />
                {validation.touched.comment && validation.errors.comment ? (
                  <p className="text-red-400">{validation.errors.comment}</p>
                ) : null}
              </div>

              {/* {isEdit && (
                                
                                <div className="xl:col-span-12">
                                    <label htmlFor="statusInput" className="inline-block mb-2 text-base font-medium">Status</label>
                                    <select className="form-input border-slate-300 focus:outline-none focus:border-custom-500" data-choices data-choices-search-false 
                                        id="statusInput"
                                        name="status"
                                        onChange={validation.handleChange}
                                        value={validation.values.status || ""}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="pending">Pending</option>
                                        <option value="approved">Approve</option>
                                        <option value="declined">Decline</option>
                                    </select>
                                    {validation.touched.status && validation.errors.status ? (
                                        <p className="text-red-400">{validation.errors.status}</p>
                                    ) : null}
                                </div>
                            )} */}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDepartmentModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update WFH" : "Create WFH"}
              </button>
              {isEdit && (
                <button
                  type="button"
                  className="text-white btn bg-red-500 border-red-500 hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-red-400/20"
                  onClick={onClickDelete}
                >
                  Delete WFH
                </button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      
    </React.Fragment>
  );
};

export default WFHRequest;
