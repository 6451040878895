import {
  Award,
  CalendarDays,
  CircuitBoard,
  Codesandbox,
  FileText,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  Share2,
  ShoppingBag,
  SquareUser,
  Table,
  Trophy,
  UserRound,
  UsersRound,
  FileCheck2,
  CalendarClock,
  Settings,
  Settings2,
} from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboard",
    link: "/dashboard",
    icon: <MonitorDot />,
    roles: ["admin", "hr", "general", "superadmin", "systemadmin"],
  },

  {
    id: "usergridview",
    label: "Employees",
    link: "/apps-users-grid",
    icon: <UsersRound />,
    parentId: "usergridview",
    roles: ["admin", "hr", "general", "superadmin", "systemadmin"],
  },

  {
    id: "workFromHomeBoard",
    label: "WFH Board",
    link: "/wfh-board",
    icon: <SquareUser />,
    parentId: "workFromHomeBoard",
    roles: ["admin", "hr", "general", "superadmin", "systemadmin"],
  },

  {
    id: "leaveApplications",
    label: "Leave",
    link: "/leave-applications",
    icon: <CalendarClock />,
    parentId: "leaveApplications",
    roles: ["admin", "hr", "general", "superadmin", "systemadmin"],
  },

  {
    id: "staffAppraisal",
    label: "Appraisals",
    link: "/appraisals",
    icon: <Trophy />,
    parentId: "staffAppraisal",
    roles: ["admin", "hr", "general", "superadmin", "systemadmin"],
  },

  // Admin Settings
  {
    label: "Admin",
    isTitle: true,
    roles: ["admin"],
  },
  {
    id: "workFromHomeRequest",
    label: "WFH Request",
    link: "/wfh-request",
    parentId: "adminRequest",
    icon: <SquareUser />,
    roles: ["admin", "superadmin"],
  },
  {
    id: "leaveRequest",
    label: "Leave Request",
    link: "/leave-request",
    parentId: "adminRequest",
    icon: <CalendarClock />,
    roles: ["admin", "superadmin"],
  },

  {
    id: "appraisalRequest",
    label: "Appraisal Request",
    link: "/appraisals-request",
    icon: <Trophy />,
    parentId: "appraisalRequest",
    roles: ["admin", "superadmin"],
  },

  {
    id: "appraisalReport",
    label: "Appraisals Report",
    link: "/appraisals-report",
    icon: <Trophy />,
    parentId: "appraisalReport",
    roles: ["superadmin"],
  },

  {
    label: "System Management",
    isTitle: true,
    roles: ["hr", "it", "superadmin", "systemadmin"],
  },
  {
    id: "leaveReport",
    label: "Leave Report",
    link: "/leave-report",
    icon: <CalendarClock />,
    parentId: "leaveReport",
    roles: ["hr", "it", "superadmin", "systemadmin"],
  },
  {
    id: "adminAppraisal",
    label: "Staff Appraisal",
    icon: <UserRound />,
    parentId: "2",
    roles: ["hr", "systemadmin"],
    subItems: [
      // {
      //   id: "appraisalList",
      //   label: "Appraisal Admin",
      //   link: "/appraisals-admin",
      //   parentId: "adminAppraisal",
      // },

      {
        id: "appraisalCategories",
        label: "Appraisal Categories",
        link: "/appraisal-categories",
        parentId: "adminAppraisal",
      },

      {
        id: "appraisalKpis",
        label: "Appraisal KPIs",
        link: "/appraisal-kpis",
        parentId: "adminAppraisal",
      },
    ],
  },

  {
    id: "users",
    label: "Users Management",
    icon: <UserRound />,
    parentId: "2",
    roles: ["hr", "systemadmin"],
    subItems: [
      {
        id: "addUsers",
        label: "Create User",
        link: "/apps-create-user",
        parentId: "users",
      },

      {
        id: "userlistview",
        label: "User List",
        link: "/apps-users-list",
        parentId: "users",
      },
    ],
  },

  {
    id: "organisation",
    label: "Organisation Structure",
    icon: <Settings />,
    parentId: "2",
    roles: ["hr", "systemadmin"],
    subItems: [
      {
        id: "department",
        label: "Department",
        icon: <CircuitBoard />,
        link: "/apps-hr-department",
        parentId: 2,
      },

      {
        id: "roles",
        label: "Roles",
        icon: <MessageSquare />,
        link: "/apps-admin-roles",
        parentId: 2,
      },

      {
        id: "positions",
        label: "Positions",
        icon: <MessageSquare />,
        link: "/apps-admin-positions",
        parentId: 2,
      },

      {
        id: "user-categories",
        label: "User categories",
        icon: <ScrollText />,
        link: "/apps-admin-userCategories",
        parentId: 2,
      },
    ],
  },
];

export { menuData };
