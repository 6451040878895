import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CreatableSelect from 'react-select/creatable';

// Icons
import { Search, Plus, Trash2, Pencil } from "lucide-react";
import TableContainer from "Common/TableContainer";

import { Link } from "react-router-dom";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getAppraisalCategories as onGetAppraisalCategories,
  getAppraisalCategoryDetails as onGetAppraisalCategoryDetails,
  getActiveAppraisalCategories as onGetActiveAppraisalCategories,
  addAppraisalCategory as onAddAppraisalCategory,
  updateAppraisalCategory as onUpdateAppraisalCategory,
  deleteAppraisalCategory as onDeleteAppraisalCategory,
} from "slices/thunk";
// import { getDepartments as onGetDepartments } from "services/api_service";
import { ToastContainer } from "react-toastify";
import Pagination from "Common/Pagination";

interface Option {
  readonly label: string;
  readonly value?: string;
  readonly options?: Option[];
  readonly isDisabled?: boolean
}

const AppraisalCategories = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (state) => ({
      dataList: state.appraisalCategories,
      dataDetails: state.appraisalCategoryDetails,
    })
  );

  const { dataList, dataDetails} = useSelector(selectDataList);

  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [category, setCategory] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const emailValues: Option[] = [
    // { label: "Project-A", value: "Project-A" },
    // { label: "Project-B", value: "Project-B" },
  ];

  const phoneValues: Option[] = [];

  const [emails, setEmail] = React.useState<readonly Option[]>([emailValues[0], emailValues[1]]);
  const [phones, setPhones] = React.useState<readonly Option[]>([phoneValues[0], phoneValues[1]]);

  // Get Data
  useEffect(() => {
    // dispatch(onGetAppraisalCategoryDetails(''));
    dispatch(onGetAppraisalCategories({ page: 1, per_page: 12 }));
  }, [dispatch]);

  useEffect(() => {
    // setCategory(dataDetails?.data);
    setData(dataList?.data);
  }, [dataList]);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteAppraisalCategory(eventData.id));
      setDeleteModal(false);
    }
  };

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      date: (eventData && eventData.timestamp) || "",
      deadline: (eventData && eventData.deadline) || "",
      year: (eventData && eventData.year) || "",
      month: (eventData && eventData.month) || "",
      type: (eventData && eventData.type) || "",
      description: (eventData && eventData.description) || "",
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      date: Yup.string().required("Please Select Date"),
      deadline: Yup.string().required("Please Select deadline"),
      year: Yup.string(),
      month: Yup.string(),
      type: Yup.string().required("Please Select Appraisal Type"),
      description: Yup.string(),
      status: Yup.string()
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateAppraisalCategory(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new user
        dispatch(onAddAppraisalCategory(newData));
      }
       toggle();
    },
  });

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  // Status
  const Status = ({ item }: any) => {
    switch (item) {
        case "opened":
            return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-blue-100 border-transparent text-blue-500 dark:bg-blue-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
        case "pending":
            return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:text-zink-200 dark:border-transparent status">{item}</span>);
        case "closed":
            return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent status">{item}</span>);
        default:
            return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent inline-flex items-center status">{item}</span>);
    }
  };

  const Type = ({ item }: any) => {
    switch (item) {
        case "half_yr":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Half Year</span>);
        case "full_yr":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Full Year</span>);
        case "confirmation":
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">Confirmations</span>);
        default:
            return (<span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">{item}</span>);
    }
  };

  // columns
  const columns = useMemo(
    () => [
      {
        header: "#",
        // accessorKey: "id: index + 1",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex gap-2">
            {cell.row.index + 1}
          </div>
        ),
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Type item={cell.getValue()} />
        ),
      },
      {
        header: "Month",
        accessorKey: "month",
        enableColumnFilter: false,
      },

      {
        header: "Year",
        accessorKey: "year",
        enableColumnFilter: false,
      },
      {
        header: "Emrolled",
        accessorKey: "total_employees",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
            <Status item={cell.getValue()} />
        ),
      },

      {
        header: "Created At",
        accessorKey: "date",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-2">
            <Link
              to="#!"
              data-modal-target="addDepartmentModal"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/20"
              onClick={() => {
                const data = cell.row.original;
                handleUpdateDataClick(data);
              }}
            >
              <Pencil className="size-4" />
            </Link>
            <Link
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 dark:bg-zink-600 dark:text-zink-200 text-slate-500 hover:text-red-500 dark:hover:text-red-500 hover:bg-red-100 dark:hover:bg-red-500/20"
              onClick={() => {
                const data = cell.row.original;
                onClickDelete(data);
              }}
            >
              <Trash2 className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  // Pagination
  const [currentPage, setCurrentPage] = useState<any>(1);

  const perPageData = 12;
  useEffect(() => {
    // console.log('currentPage', currentPage);
    setCurrentPage(currentPage);
      // setData(currentdata);
      // handlePagination(currentPage);
      dispatch(onGetAppraisalCategories({ page: currentPage, per_page: perPageData }));
      
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <BreadCrumb title="Appraisal Category" pageTitle="Appraisal" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
            <h6 className="text-15 grow">Categories</h6>
            <div className="shrink-0">
              <Link
                to="#!"
                data-modal-target="addDepartmentModal"
                type="button"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                onClick={toggle}
              >
                <Plus className="inline-block size-4" />
                <span className="align-middle">Create New Category</span>
              </Link>
            </div>
          </div>
          {data && data.length > 0 ? (
            <TableContainer
              isPagination={false}
              columns={columns || []}
              data={data || []}
              customPageSize={12}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right"
              thclassName="px-3.5 py-2.5 font-semibold border border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 border border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">
                  We've searched more than 5+ times We did not find any
                  appraisal category data for you search.
                </p>
              </div>
            </div>
          )}

        <Pagination
          perPageData={perPageData}
          data={dataList ? dataList?.data : dataList}
          total={dataList ? dataList?.meta?.total : 0}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currentdata={dataList? dataList?.data : []}
        />
        </div>
      </div>

      {/* Department Modal */}

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit Appraisal Confirmation" : "Add Appraisal Confirmation"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">

              <div className="xl:col-span-12">
                <label
                  htmlFor="typeInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Appraisal Type
                </label>
                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  id="typeInput"
                  name="type"
                  onChange={validation.handleChange}
                  value={validation.values.type || ""}
                >
                  <option value="">Select Type</option>
                  <option value='half_yr'> Half Year </option>
                  <option value='full_yr'> Full Year </option>
                  <option value='confirmation'> Confirmation </option>
                </select>
                {validation.touched.type && validation.errors.type ? (
                  <p className="text-red-400">{validation.errors.type}</p>
                ) : null}
              </div>
             
              <div className="xl:col-span-12">
                <label
                  htmlFor="descriptionsInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Descriptions
                </label>
                <input
                  type="text"
                  id="descriptionsInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Descriptions"
                  name="description"
                  onChange={validation.handleChange}
                  value={validation.values.description || ""}
                />
                {validation.touched.description && validation.errors.description ? (
                  <p className="text-red-400">{validation.errors.description}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                  <label
                    htmlFor="deadlineDateInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Deadline date
                  </label>
                  <input
                    type="date"
                    id="deadlineDateInput"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Apraisal deadline"
                    name="deadline"
                    onChange={validation.handleChange}
                    value={validation.values.deadline || ""}
                  />
                  {validation.touched.deadline && validation.errors.deadline ? (
                    <p className="text-red-400">{validation.errors.deadline}</p>
                  ) : null}
                </div>

              {!isEdit && (
                <div className="xl:col-span-12">
                  <label
                    htmlFor="appraisalDateInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Appraisal date
                  </label>
                  <input
                    type="date"
                    id="appraisalDateInput"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Apraisal date"
                    name="date"
                    onChange={validation.handleChange}
                    value={validation.values.date || ""}
                  />
                  {validation.touched.date && validation.errors.date ? (
                    <p className="text-red-400">{validation.errors.date}</p>
                  ) : null}
                </div>
              )}

              {isEdit && (
                <div className="xl:col-span-12">
                    <label htmlFor="statusInput" className="inline-block mb-2 text-base font-medium">Status</label>
                    <select className="form-input border-slate-300 focus:outline-none focus:border-custom-500" data-choices data-choices-search-false 
                        id="statusInput"
                        name="status"
                        onChange={validation.handleChange}
                        value={validation.values.status || ""}
                    >
                        <option value="">Select Status</option>
                        <option value="pending">Pending</option>
                        <option value="opened">Opened</option>
                        <option value="closed">Closed</option>
                    </select>
                    {validation.touched.status && validation.errors.status ? (
                        <p className="text-red-400">{validation.errors.status}</p>
                    ) : null}
                </div>
              )}

            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDepartmentModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Add Department"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AppraisalCategories;
