import React, { ChangeEvent, useEffect, useState } from "react";
import {
  BadgeCheck,
  Dribbble,
  Facebook,
  Github,
  Globe,
  ImagePlus,
  Instagram,
  Linkedin,
  Mail,
  MapPin,
  MoreHorizontal,
  UserCircle,
} from "lucide-react";

//import images
import avatar1 from "assets/images/users/avatar-1.png";

// react-redux
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const WelcomeWidget = () => {
  const selectDataList = createSelector(
    (state: any) => state.Users,
    (hrState: any) => hrState.HRManagment,
    (state, hrState) => ({
      dataList: state.userProfile,
      checkAppraisal: hrState.checkAppraisalCategories,
    })
  );
  const { dataList, checkAppraisal } = useSelector(selectDataList);
  const [user, setData] = useState<any>({});
  const [appraisal, setAppraisal] = useState<any>({});

  useEffect(() => {
    setData(dataList?.data);
    setAppraisal(checkAppraisal?.data);
  }, [dataList, checkAppraisal]);

  const [selectedImage, setSelectedImage] = React.useState<
    string | ArrayBuffer | null
  >(avatar1);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  return (
    <React.Fragment>
      <div className="relative col-span-12 overflow-hidden card md:col-span-8">
        {/* <div className="relative card-body">
                    <div className="grid items-center grid-cols-12">
                        <div className="col-span-12 lg:col-span-8 2xl:col-span-7">

                            <h5 className="mb-3 font-normal tracking-wide text-slate-200">Welcome Paula Keenan 🎉</h5>
                            <p className="mb-5 text-slate-400">An ecommerce dashboard has just that purpose. It provides your ecommerce team with a clear overview of key financial and website KPIs at any time.</p>
                            <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-500/20 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-500/20 dark:ring-custom-400/20">Take a Product</button>
                        </div>
                        <div className="hidden col-span-12 2xl:col-span-3 lg:col-span-2 lg:col-start-11 2xl:col-start-10 lg:block">
                            <img src={dashboard} alt="" className="h-40 ltr:2xl:ml-auto rtl:2xl:mr-auto" />
                        </div>
                    </div>
                </div> */}

        <div className="relative card-body">
          {/* <div className="grid items-center grid-cols-12"> */}

          <div className="grid grid-cols-1 gap-5 lg:grid-cols-12 2xl:grid-cols-12">
            <div className="lg:col-span-2 2xl:col-span-2">
              <div className="relative image-center inline-block size-20 rounded-full shadow-md bg-slate-100 profile-user xl:size-28">
                <img
                  src={user?.image ?? avatar1}
                  alt=""
                  className="object-cover border-0 rounded-full img-thumbnail user-profile-image"
                />
              </div>
            </div>
            <div className="pl-2 lg:col-span-10 2xl:col-span-10 ">
              <h5 className="mb-1">
                Welcome {user?.firstname} {user?.othernames} {user?.lastname} 
                <BadgeCheck
                className={`inline-block size-4 ${
                  user?.isConfirmed
                    ? "text-green-500 fill-green-100"
                    : "text-red-500 fill-red-100"
                } dark:fill-custom-500/20`}
              ></BadgeCheck>
              </h5>
              <div className="flex gap-3 mb-3">
                <p className="text-slate-500 dark:text-zink-200">
                  <UserCircle className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></UserCircle>{" "}
                  {user?.role ? user?.position?.name : "NA"}
                </p>
                <p className="text-slate-500 dark:text-zink-200">
                  <UserCircle className="inline-block size-4 ltr:mr-1 rtl:ml-1 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-500"></UserCircle>{" "}
                  {user?.role ? user.department.name : "NA"}
                </p>
              </div>

              <div className="flex gap-3 mb-3">
                <p className=" text-slate-500 dark:text-zink-200 ">
                  <b>Supervisor:</b> {user ? user?.supervisor : ""}
                </p>
              </div>
              <ul className="flex flex-wrap gap-3 mt-4 text-center divide-x divide-slate-200 dark:divide-zink-500 rtl:divide-x-reverse">
                <li className="px-5 w-[100%] md:w-[30%]">
                  <h5>{user ? user?.total_leave_days : "NA"}</h5>
                  <p className="text-slate-500 dark:text-zink-200">
                    Total Leave Days
                  </p>
                </li>
                <li className="px-5 w-[100%] md:w-[30%]">
                  <h5>{user ? user?.taken_leave_days : 0}</h5>
                  <p className="text-slate-500 dark:text-zink-200">
                    Total Taken
                  </p>
                </li>
                <li className="px-5 w-[100%] md:w-[30%]">
                  <h5>{user ? user?.pending_leave_days : 0}</h5>
                  <p className="text-slate-500 dark:text-zink-200">
                    Total Remaining
                  </p>
                </li>
              </ul>

              {appraisal?.status ? (
                <div className="px-4 py-3 mb-2 mt-2 text-sm text-blue-500 border border-blue-200 rounded-md bg-blue-50 dark:bg-blue-400/20 dark:border-blue-500/50">
                  <span className="font-bold">
                    {appraisal?.notification} Alert !!!:
                  </span>{" "}
                  {appraisal?.category?.type == "half_yr"
                    ? `Half Year - ${appraisal?.category?.month}, ${appraisal?.category?.year} Appraisal`
                    : appraisal?.category?.type == "full_yr"
                    ? `Full Year - ${appraisal?.category?.month}, ${appraisal?.category?.year} Appraisal`
                    : `Confirmation - ${appraisal?.category?.month}, ${appraisal?.category?.year}`}{" "}
                  is open FYA, Kindly click on Start Now button to proceed with
                  your appraisal process.
                  <Link
                    to={`/appraisals`}
                    className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border transition-all duration-200 ease-linear bg-blue-100 border-transparent text-blue-500 hover:bg-blue-200 dark:bg-blue-400/20 dark:hover:bg-blue-400/30 dark:border-transparent ltr:ml-1 rtl:mr-1"
                  >
                    Start Now
                  </Link>
                </div>
              ) : (
                ""
              )}

            </div>
          </div>

          {/* </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default WelcomeWidget;
