import React, { useCallback, useEffect, useMemo, useState } from "react";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import BreadCrumb from "Common/BreadCrumb";
import TableContainer from "Common/TableContainer";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

// Icons
import {
  Search,
  Plus,
  Pencil,
  Info,
  FileBarChart2,
  CalendarDays,
  CalendarCheck,
  CalendarFold,
  CalendarOff,
  Anchor,
  Trash2,
} from "lucide-react";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getActiveUsers as onGetUserList,
  getUserLeaveInfo as onGetUserLeaveInfo,
  getActiveLeaveTypes as onGetActiveLeaveTypes,
  getUserLeaveApplications as onGetUserLeaveApplications,
  getActiveLeaveApplications as onGetActiveLeaveApplications,
  createLeaveApplication as onCreateLeaveApplication,
  updateLeaveApplication as onUpdateLeaveApplication,
  deleteLeaveApplication as onDeleteLeaveApplication,
  getUserProfile as onGetUserProfile,
} from "slices/thunk";
import { toast, ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";

const LeaveManageEmployee = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (user: any) => user.Users,
    (state, user) => ({
      dataList: state.userLeaveApplications,
      typeList: state.activeLeaveTypes,
      userInfo: user.userLeaveInfo,
      userList: user.activeUsers,
      profileList: user.userProfile,
    })
  );

  const { dataList, typeList, userInfo, userList, profileList } =
    useSelector(selectDataList);

  const [data, setData] = useState<any>([]);
  const [types, setType] = useState<any>([]);
  const [userInfoData, setUserInfoData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [users, setUser] = useState<any>([]);
  const [profile, setProfile] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const perPageData = 12;

  // Get Data
  useEffect(() => {
    dispatch(onGetActiveLeaveTypes());
    dispatch(onGetUserLeaveInfo());
    dispatch(onGetUserList());
    dispatch(onGetUserProfile());
    dispatch(
      onGetUserLeaveApplications({ page: currentPage, per_page: perPageData })
    );
  }, [currentPage, dispatch]);

  useEffect(() => {
    setData(dataList?.data);
    setType(typeList?.data);
    setUser(userList?.data);
    setUserInfoData(userInfo?.data);
    setProfile(profileList?.data);
  }, [dataList, profileList, typeList, userInfo, userList]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = [
      "leaveType",
      "reason",
      "noOfDays",
      "from",
      "to",
      "approvedBy",
      "status",
    ];
    filterDataBySearch(dataList, search, keysToSearch, setData);
  };

  // Table Head Action On Right
  useEffect(() => {
    const tableHead = document.querySelectorAll("th");
    const lastTheadElement = tableHead[tableHead.length - 1];
    lastTheadElement?.classList.add("ltr:text-right", "rtl:text-left");

    return () => {
      lastTheadElement?.classList.remove("ltr:text-right", "rtl:text-left");
    };
  });

  const Status = ({ item }: any) => {
    switch (item) {
      case "approved":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            Approved
          </span>
        );
      case "pending":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Pending
          </span>
        );
      case "declined":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent">
            Declined
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            {item}
          </span>
        );
    }
  };

  const Type = ({ item }: any) => {
    switch (item) {
      case "half_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Half Year
          </span>
        );
      case "full_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Full Year
          </span>
        );
      case "confirmation":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Confirmations
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            {item}
          </span>
        );
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "ref_no",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="!#"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            {" "}
            <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
              {cell.getValue()}
            </span>
          </Link>
        ),
      },
      {
        header: "Leave Type",
        accessorKey: "type_name",
        enableColumnFilter: false,
      },
      {
        header: "Leave Days",
        accessorKey: "leave_days",
        enableColumnFilter: false,
      },
      {
        header: "Total No. of Days",
        accessorKey: "total_leave_days",
        enableColumnFilter: false,
      },
      {
        header: "Relief Staff",
        accessorKey: "relief_user",
        enableColumnFilter: false,
      },
      {
        header: "Start Date",
        accessorKey: "start_date",
        enableColumnFilter: false,
      },
      {
        header: "End",
        accessorKey: "end_date",
        enableColumnFilter: false,
      },
      {
        header: "Allowance",
        accessorKey: "is_allowance",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      {
        header: "Date Applied",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <div className="flex justify-end gap-2">
            {cell.row.original.status === "pending" ? (
              <>
                <Link
                  to="#!"
                  className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-slate-500 bg-slate-100 hover:text-white hover:bg-slate-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                  onClick={() => {
                    const data = cell.row.original;
                    handleUpdateDataClick(data);
                  }}
                >
                  <Pencil className="size-4" />
                </Link>
                <Link
                  to="#!"
                  className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                  onClick={() => {
                    const data = cell.row.original;
                    onClickDelete(data);
                  }}
                >
                  <Trash2 className="size-4" />
                </Link>
              </>
            ) : null}

            <Link
              to="#!"
              data-modal-target="leaveOverviewModal"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-custom-500 bg-custom-100 hover:text-white hover:bg-custom-500 dark:bg-custom-500/20 dark:hover:bg-custom-500"
            >
              <Info className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteLeaveApplication(eventData.id));
      setDeleteModal(false);
    }
  };
  //

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      relief_user_id: (eventData && eventData.relief_user_id) || "",
      leave_type_id: (eventData && eventData.leave_type_id) || "",
      entitled_days: (eventData && eventData.entitled_days) || 0,
      carried_over_days: (eventData && eventData.carried_over_days) || 0,
      total_vacation_days: (eventData && eventData.total_vacation_days) || 0,
      total_taken: (eventData && eventData.total_taken) || 0,
      total_not_taken: (eventData && eventData.total_not_taken) || 0,
      public_holidays: (eventData && eventData.public_holidays) || 0,
      leave_days: (eventData && eventData.leave_days) || 0,
      total_leave_days: (eventData && eventData.total_leave_days) || 0,
      is_allowance: (eventData && eventData.is_allowance) || false,
      leave_note: (eventData && eventData.leave_note) || "",
      start_date:
        (eventData &&
          new Date(eventData.start_date).toISOString().split("T")[0]) ||
        "",
      end_date:
        (eventData &&
          new Date(eventData.end_date).toISOString().split("T")[0]) ||
        "",
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      relief_user_id: Yup.string(),
      leave_type_id: Yup.string().required("Please Select Leave Type"),
      entitled_days: Yup.number(),
      carried_over_days: Yup.number(),
      total_vacation_days: Yup.number(),
      total_taken: Yup.number(),
      total_not_taken: Yup.number(),
      public_holidays: Yup.number(),
      leave_days: Yup.number().required("Please Enter Your Leave Days"),
      total_leave_days: Yup.number(),
      is_allowance: Yup.boolean(),
      leave_note: Yup.string(),
      start_date: Yup.string().required("Please Enter Your Start Date"),
      end_date: Yup.string().required("Please Enter Your End Date"),
      status: Yup.string(),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateLeaveApplication(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // console.log("newData", newData);
        // save new user
        dispatch(onCreateLeaveApplication(newData));
      }
      toggle();
    },
  });

  useEffect(() => {
    if (validation.values.leave_type_id) {
      const leaveType: any = types?.filter(
        (type: any) => type?.id === validation.values.leave_type_id
      );
      console.log('leaveType', leaveType);
      if (leaveType[0].slug == 'annual_leave') {
        validation.values.entitled_days = +leaveType[0]?.entitled_days;
        validation.values.carried_over_days = userInfoData?.leave_carried_over;
        validation.values.total_vacation_days =
          +leaveType[0]?.entitled_days + +userInfoData?.leave_carried_over;
        validation.values.total_taken = userInfoData?.taken_leave_days;
        validation.values.total_not_taken = userInfoData?.pending_leave_days;
        // validation.values.total_not_taken = validation.values.total_vacation_days - validation.values.total_taken;
      } else {
        validation.values.total_not_taken = leaveType[0].slug == 'sick_leave' ? userInfoData?.sick_leave_days :
                                            leaveType[0].slug == 'exam_leave' ? userInfoData?.exam_leave_days :
                                            leaveType[0].slug == 'maternity_leave' ? userInfoData?.maternity_leave_days :
                                            leaveType[0].slug == 'paternity_leave' ? userInfoData?.paternity_leave_days :
                                            leaveType[0].slug == 'training_leave' ? userInfoData?.training_leave_days :
                                            leaveType[0].slug == 'bereavement_leave' ? 0 : userInfoData.pending_leave_days;

        validation.values.entitled_days = +leaveType[0]?.entitled_days;
        validation.values.carried_over_days = 0;
        validation.values.total_vacation_days = +leaveType[0]?.entitled_days + 0;
        validation.values.total_taken = +leaveType[0]?.entitled_days - validation.values.total_not_taken;        
      }

    }

    if (validation.values.start_date && validation.values.end_date) {
      let startDate = new Date(validation.values.start_date);
      let endDate = new Date(validation.values.end_date);

      // const timeDifference = +endDate - +startDate;
      // const totalDays = timeDifference / (1000 * 3600 * 24) + 1;

      // Ensure startDate is earlier than endDate
      let currentDate = new Date(startDate);
      let totalDays = 0;

      // Loop through each day from startDate to endDate
      while (currentDate <= endDate) {
        // Check if the day is a weekend (Saturday or Sunday)
        const dayOfWeek = currentDate.getDay(); // Sunday = 0, Saturday = 6
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          totalDays++; // Count the day as a working day
        }
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }

      const publicHolidays = validation.values.public_holidays || 0;
      validation.values.leave_days = +totalDays;
      validation.values.total_leave_days = +totalDays;
      // console.log("total_leave_days", validation.values.total_leave_days);
    }
  }, [
    validation.values.leave_type_id,
    validation.values.start_date,
    validation.values.end_date,
    validation.values.public_holidays,
    validation.values,
    types,
    userInfoData?.leave_carried_over,
    userInfoData?.taken_leave_days,
    userInfoData?.pending_leave_days,
  ]);

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  useEffect(() => {
    setCurrentPage(currentPage);
    dispatch(
      onGetUserLeaveApplications({ page: currentPage, per_page: perPageData })
    );
  }, [dispatch, currentPage]);

  const filteredUsers = users?.filter(
    (user: any) => user?.position?.user_category?.name !== "Contract"
  );

  return (
    <React.Fragment>
      <BreadCrumb
        title="Leave Management (Employee)"
        pageTitle="Leaves Management"
      />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-12">
        <div className="xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-700">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-750 rounded-md text-15 shrink-0">
                <CalendarDays />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16 text-white">
                  <CountUp
                    end={userInfoData?.entitled_leave_days}
                    className="counter-value text-white"
                  />
                  /
                  <CountUp
                    end={userInfoData?.leave_carried_over}
                    className="counter-value text-white"
                  />
                </h5>
                <p className="text-white">Entitled/Leave CO</p>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-800">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-850 rounded-md text-15 shrink-0">
                <CalendarCheck />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={userInfoData?.total_leave_days}
                    className="counter-value text-white"
                  />
                </h5>
                <p className="text-white">Total Annual Leave</p>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-900">
              <div className="flex items-center justify-center size-12 text-white bg-agusto-950 rounded-md text-15 shrink-0">
                <CalendarOff />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={userInfoData?.taken_leave_days}
                    className="counter-value text-white"
                  />
                </h5>
                <p className="text-white">Leave Taken</p>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-3 card-body bg-agusto-1000">
              <div className="flex items-center justify-center size-12 rounded-md text-white bg-agusto-1050 text-15 shrink-0">
                <CalendarFold />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={userInfoData?.pending_leave_days}
                    className="counter-value text-white"
                  />
                </h5>
                <p className="text-white">Remaining Leave</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="grid grid-cols-1 gap-4 mb-5 lg:grid-cols-2 xl:grid-cols-12">
            <div className="xl:col-span-3">
              <div className="relative">
                <input
                  type="text"
                  className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Search for ..."
                  autoComplete="off"
                  onChange={(e) => filterSearchData(e)}
                />
                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
              </div>
            </div>
            <div className="xl:col-span-2 xl:col-start-11">
              <div className="ltr:lg:text-right rtl:lg:text-left">
                <Link
                  to="#!"
                  data-modal-target="leaveModal"
                  onClick={toggle}
                  type="button"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  <Plus className="inline-block size-4" />
                  <span className="align-middle">Apply</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            {data && data.length > 0 ? (
              <TableContainer
                isPagination={true}
                columns={columns || []}
                data={data || []}
                customPageSize={10}
                divclassName="overflow-x-auto"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:bg-zink-600 dark:text-zink-200"
                thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            ) : (
              <div className="noresult">
                <div className="py-6 text-center">
                  <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                  <h5 className="mt-2 mb-1">Sorry! No Data Yet</h5>
                  {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                    We've searched more than 5+ Departments We did not find any
                    Departments for you search.
                  </p> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit Leave" : "Add Leave"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-12">
              <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="leaveType"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Leave Type
                  </label>
                  <select
                    className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                    data-choices
                    data-choices-search-false
                    id="userCategoryInput"
                    name="leave_type_id"
                    onChange={validation.handleChange}
                    value={validation.values.leave_type_id || ""}
                  >
                    <option value="">Select Leave Type</option>
                    {types?.map((type: any) => (
                      <option key={type.id} value={type.id}>
                        {type.type_name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.leave_type_id &&
                  validation.errors.leave_type_id ? (
                    <p className="text-red-400">
                      {validation.errors.leave_type_id}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="entitledDaysLeaves"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Entitled Leaves
                  </label>
                  <input
                    type="number"
                    id="entitledDaysLeaves"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="entitled_days"
                    onChange={validation.handleChange}
                    value={validation.values.entitled_days || 0}
                    disabled
                  />
                </div>
              </div>

              <div className="xl:col-span-4">
                <div>
                  <label
                    htmlFor="totalLeavesdays"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Total Entitled Leaves
                  </label>
                  <input
                    type="number"
                    id="totalLeavesdays"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="total_vacation_days"
                    onChange={validation.handleChange}
                    value={validation.values.total_vacation_days || 0}
                    disabled
                  />
                </div>
              </div>

              <div className="xl:col-span-4">
                <div>
                  <label
                    htmlFor="LeaveCarriedOver"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Leaves Carried Over
                  </label>
                  <input
                    type="number"
                    id="LeaveCarriedOver"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="carried_over_days"
                    onChange={validation.handleChange}
                    value={validation.values.carried_over_days || 0}
                    disabled
                  />
                </div>
              </div>

              <div className="xl:col-span-4">
                <div>
                  <label
                    htmlFor="TotalNotTakenLeave"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Remaining Leaves
                  </label>
                  <input
                    type="number"
                    id="TotalNotTakenLeave"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="total_not_taken"
                    onChange={validation.handleChange}
                    value={validation.values.total_not_taken || 0}
                    disabled
                  />
                </div>
              </div>

              <div className="xl:col-span-5">
                <label
                  htmlFor="startDateInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDateInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="User Category name"
                  name="start_date"
                  onChange={validation.handleChange}
                  value={validation.values.start_date || ""}
                />
                {validation.touched.start_date &&
                validation.errors.start_date ? (
                  <p className="text-red-400">{validation.errors.start_date}</p>
                ) : null}
              </div>
              <div className="xl:col-span-5">
                <label
                  htmlFor="endDateInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="endDateInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="User Category name"
                  name="end_date"
                  onChange={validation.handleChange}
                  value={validation.values.end_date || ""}
                />
                {validation.touched.end_date && validation.errors.end_date ? (
                  <p className="text-red-400">{validation.errors.end_date}</p>
                ) : null}
              </div>

              <div className="xl:col-span-2">
                <div>
                  <label
                    htmlFor="Leavesdays"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    No of Days
                  </label>
                  <input
                    type="number"
                    id="Leavesdays"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="leave_days"
                    onChange={validation.handleChange}
                    value={validation.values.leave_days}
                    // disabled
                  />
                  {validation.touched.leave_days &&
                  validation.errors.leave_days ? (
                    <p className="text-red-400">
                      {validation.errors.leave_days}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="publicHolidays"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    No. of Holidays
                  </label>
                  <input
                    type="number"
                    id="publicHolidays"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="public_holidays"
                    onChange={validation.handleChange}
                    value={validation.values.public_holidays || 0}
                  />
                </div>
              </div>

              <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="numberOfDayLeaves"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Total Leave Days
                  </label>
                  <input
                    type="number"
                    id="numberOfDayLeaves"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="total_leave_days"
                    onChange={validation.handleChange}
                    value={validation.values.total_leave_days || 0}
                    disabled
                  />
                </div>
              </div>

              <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="numberOfDayLeaves"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Supervisor
                  </label>
                  <input
                    type="text"
                    id="numberOfDayLeaves"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    value={userInfoData?.supervisor || ""}
                    disabled
                  />
                </div>
              </div>

              <div className="xl:col-span-6">
                <label
                  htmlFor="relief_user_idInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Relief Staff
                </label>

                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  id="relief_user_idInput"
                  name="relief_user_id"
                  onChange={validation.handleChange}
                  value={validation.values.relief_user_id || ""}
                >
                  <option value="">Select User</option>
                  {filteredUsers?.map((user: any) => (
                    <option key={user?.id} value={user?.id}>
                      {user?.firstname} {user?.lastname}
                    </option>
                  ))}
                </select>
                {validation.touched?.relief_user_id &&
                validation.errors?.relief_user_id ? (
                  <p className="text-red-400">
                    {validation.errors.relief_user_id}
                  </p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="isAllowance"
                  className="inline-block text-base font-medium cursor-pointer ltr:mr-2 rtl:ml-2"
                >
                  Leave Allowance Status
                </label>
                <div className="relative inline-block w-10 align-middle transition duration-200 ease-in">
                  <input
                    type="checkbox"
                    id="isAllowance"
                    name="is_allowance"
                    onChange={validation.handleChange}
                    value={validation.values.is_allowance || ""}
                    checked={validation.values.is_allowance}
                    className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none"
                  />
                  <label
                    htmlFor="customDefaultSwitch"
                    className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"
                  ></label>

                  {validation.touched.is_allowance &&
                  validation.errors.is_allowance ? (
                    <p className="text-red-400">
                      {validation.errors.is_allowance}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="md:col-span-2 xl:col-span-12">
                <div>
                  <label
                    htmlFor="leaveNoteInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Leave Handover Note
                  </label>
                  <textarea
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    id="leaveNoteInput"
                    rows={4}
                    name="leave_note"
                    onChange={validation.handleChange}
                    value={validation.values.leave_note || ""}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDepartmentModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Apply "}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default LeaveManageEmployee;
